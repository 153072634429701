<template>
  <div class="dashboard-userdata">
    <h3>Ihre Reisen</h3>
    <p class="introtext">
      In diesem Bereich sehen Sie alle gebuchten Reisen in der Übersicht und können sie jederzeit verwalten.
    </p>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>