<template>
  <div class="search-form">
      <h2>Finden Sie Ihre Traumreise</h2>
      
      <div class="error" v-if="error!=''">{{ error }}</div>

      <div v-if="!runningSearch">
        <form @submit.prevent="submitForm">
            <div class="form-fields">
                <h4>Reisedatum</h4>
                <div class="row mb-3">
                  <div class="col-6">
                    <span class="input-group-text">Frühestes Abreisedatum</span>
                    <input type="date" class="form-control" name="start-date" v-model="startDate" :min="todayDate" :max="maxCruiseDate" required>
                  </div>
                  <div class="col-6">
                    <span class="input-group-text">Spätestes Rückreisedatum</span>
                    <input type="date" class="form-control" name="end-date" v-model="endDate" :max="maxCruiseDate">
                  </div>
                </div>

                <h4>Reiseveranstalter &amp; Region</h4>
                <div class="row mb-3">
                  <div class="col-6">
                      <span class="input-group-text">Reederei(en)</span>
                      <!-- <select name="company" v-model="company" id="selectCompany" class="form-select">
                          <option value="ALL">Egal</option>
                          <option v-for="comp in companies" :key="comp.shippingLineId" :value="comp.shippingLineId">{{comp.shippingLineName}}</option>
                      </select> -->
                      <div class="d-flex">
                        <Multiselect mode="tags" v-model="company" id="selectCompany" :options="companies" :searchable="true" :close-on-select="false" @change="updateSearchCriteria" ref="companiesSelect" />
                        <button class="btn btn-sm btn-multiselect ml-1" @click.prevent="selectCompanies('all')">Alle</button>
                      </div>
                  </div>
                  <div class="col-6">
                      <span class="input-group-text">Region(en)</span>
                      <!-- <select name="region" v-model="region" id="selectRegion" class="form-select">
                          <option value="ALL">Egal</option>
                          <option v-for="reg in regions" :key="reg.regionId" :value="reg.regionId">{{reg.regionName}}</option>
                      </select> -->
                     <div class="d-flex">
                      <Multiselect  mode="tags" v-model="region" id="selectRegion" :options="regions" :searchable="true" :close-on-select="false" @change="updateSearchCriteria" ref="regionsSelect" />
                      <button class="btn btn-sm btn-multiselect ml-1" @click.prevent="selectRegions('all')">Alle</button>                        
                     </div>
                  </div>
                </div>                      

                <h4>Reisende Personen</h4>
                <div class="row mb-3">
                  <div class="col-6">    
                      <span class="input-group-text">Anzahl Erwachsene</span>
                      <input type="number" class="form-control" name="adults" v-model="adults" min="1" max="5" required>
                  </div>
                  <div class="col-6">
                      <span class="input-group-text">Anzahl Kinder</span>
                      <input type="number" class="form-control" name="children" v-model="children" min="0" max="5" required>
                  </div>
                </div>     
                <div class="d-flex justify-content-between">
                    <div class="form-field" v-if="children>0">
                        <span class="input-group-text" id="age_child1">Alter 1. Kind</span>
                        <input type="number" id="ss_child1_age" name="child1_age" class="form-control" aria-label="Alter 1. Kind" aria-describedby="age_child1" v-model="child1age" min="0" max="25" required>
                    </div>  
                    <div class="form-field" v-if="children>1">
                        <span class="input-group-text" id="age_child2">Alter 2. Kind</span>
                        <input type="number" id="ss_child2_age" name="child2_age" class="form-control" aria-label="Alter 2. Kind" aria-describedby="age_child2" v-model="child2age" min="0" max="25" required>
                    </div>
                    <div class="form-field" v-if="children>2">
                        <span class="input-group-text" id="age_child3">Alter 3. Kind</span>
                        <input type="number" id="ss_child3_age" name="child3_age" class="form-control" aria-label="Alter 3. Kind" aria-describedby="age_child3" v-model="child3age" min="0" max="25" required>
                    </div> 
                </div>                                                 


                <transition name="fade">
                <div ref="hiddenFields" id="hiddenFields" v-if="showHiddenFields">
                  - derzeit keine weiteren Filter -
                </div>
                </transition>

                <div class="d-block d-md-flex justify-content-between">
                    <div class="more-filters">
                        <span @click="toggleHiddenFields">Weitere Filter ein/ausblenden</span>
                    </div>                  
                    <div class="form-submit">
                        <button type="submit" class="btn btn-primary" :disabled="!btnFilterEnabled">Jetzt Reise finden</button>
                    </div>
                </div>
            </div>
        </form>
      </div>
      <div v-else>
          <Spinner />
      </div>
  </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { inject, onBeforeMount, onMounted, onUpdated } from '@vue/runtime-core';
//Components
import Spinner from '../Spinner.vue';
import Multiselect from '@vueform/multiselect';
//Composables
import useBookingAPI from '../../composables/useBookingAPI';
import helperFunctions from '../../composables/helperFunctions';
import useMetaAPI from '../../composables/useMetaAPI';

export default {
  components: { Spinner, Multiselect },
  setup(props, context) {
    const lang = inject('lang');
    const JWTToken = inject('JWTToken');

    const error = ref('');
    const router = useRouter();        
    
    const { getYMDDate, setSearchCriteria, getSearchCriteria, setCalcCriteria } = helperFunctions();
    let now = getYMDDate(new Date());

    const hiddenFields = ref(null);
    const showHiddenFields = ref(false);

    const startDate = ref(now);
    const endDate = ref('');
    const company = ref(['ALL']);
    const region = ref(['ALL']);
    const adults = ref(2);
    const children = ref(0);
    const child1age = ref(10);
    const child2age = ref(10);
    const child3age = ref(10);
    const companies = ref([]);
    const regions = ref([]);
    
    let searchCriteria = null;
    const runningSearch = ref(false);
    const btnFilterEnabled = ref(false);

    const companiesSelect = ref(null);
    const regionsSelect = ref(null);

    let today = new Date();
    const todayDate = getYMDDate(today);
    const maxCruiseDate = getYMDDate(new Date(today.setMonth(today.getMonth()+18)));

    const { BookingApiError, getAllCompanies } = useBookingAPI();
    const { getRegions } = useMetaAPI();

    /**
     * Toggles hidden form fields
     */
    const toggleHiddenFields = () => {
      showHiddenFields.value = !showHiddenFields.value;
      // if(showHiddenFields.value) hiddenFields.value.style="display:block";
      // else hiddenFields.value.style="display:none";
    }         

    onMounted( () => { 
      error.value = ''; 
      let allCompanies = [];
      let allRegions = [];

      //Fetch all Shippinglines
      getAllCompanies(JWTToken)
        .then((result) => {
          if(result) {
            // companies.value = [ ...result.shippinglines];  for regular html select element
            allCompanies.push({value:'ALL', label: 'Egal'});
            result.shippinglines.forEach((key, index) => {
              allCompanies.push({value: key.shippingLineId, label: key.shippingLineName});
            }) 
            companies.value = allCompanies;
          } else {
            error.value += BookingApiError;
          }
        })
        .catch((err) => { console.log(err), error.value += BookingApiError });

      getRegions('', JWTToken)
        .then((result) => {
          if(result) {
            // regions.value = [ ...result.regions];
            allRegions.push({value:'ALL', label: 'Egal'});
            result.regions.forEach((key, index) => {
              if(key.regionId > 0) allRegions.push({value: key.regionId, label: key.regionName});
            }) 
            regions.value = allRegions;        
          } else {
            error.value += BookingApiError;
          }
        })
        .catch((err) => { console.log(err), error.value += BookingApiError });

      let storedSearchCriteria = getSearchCriteria();
      if(storedSearchCriteria) {
        //If there are already search criteria stored in local storage, apply them to the form fields
        startDate.value = storedSearchCriteria.from;
        endDate.value = storedSearchCriteria.to;
        company.value = storedSearchCriteria.shippingline;
        region.value = storedSearchCriteria.region;
        adults.value = storedSearchCriteria.adults;
        children.value = storedSearchCriteria.children;
        child1age.value = storedSearchCriteria.child1age;
        child2age.value = storedSearchCriteria.child2age;
        child3age.value = storedSearchCriteria.child3age;

        if(adults.value != 2 || children.value > 0 || region.value != 'ALL') {
          showHiddenFields.value = false;
          toggleHiddenFields();
        }
      }
    });

    onUpdated(() => {
      updateSearchCriteria();
    });

    const updateSearchCriteria = () => {
      //Companies select may only be 'Egal' or a selection of specific companies
      if(company.value.length<1) {
        company.value = ['ALL'];
      } else if(company.value.length>1) {
        let allIndex = company.value.indexOf('ALL');
        if(allIndex > -1) company.value.splice(allIndex, 1);
      }

      //Regions select may only be 'Egal' or a selection of specific regions
      if(region.value.length<1) {
        region.value = ['ALL'];
      } else if(region.value.length>1) {
        let allIndex = region.value.indexOf('ALL');
        if(allIndex > -1) region.value.splice(allIndex, 1);
      }      

      //form data was changed by user -> store it to the search criteria object
      searchCriteria = {
        from: startDate.value,
        to: endDate.value,
        shippingline: company.value,
        region: region.value,
        adults: adults.value,
        children: children.value, 
        child1age: child1age.value,
        child2age: child2age.value,
        child3age: child3age.value,
        junior: 0,
        timestamp: Date.now()
      };
      
      //enable or disable search-button depending on existing search criteria
      if(searchCriteria.startDate != '' && searchCriteria.endDate != '' && searchCriteria.adults > 0) {
        btnFilterEnabled.value = true;
      } else {
        btnFilterEnabled.value = false;
      }        
    }

    const selectCompanies = (param) => {
      if(param=='all') {
        companiesSelect.value.selectAll();
        company.value = ['ALL'];
      } else {
        companiesSelect.value.clear();
      } 
    };

    const selectRegions = (param) => {
      if(param=='all') {
        regionsSelect.value.selectAll();
        region.value = ['ALL'];
      } else {
        regionsSelect.value.clear();
      } 
    };    

    /**
     * Submit form function
     */
    const submitForm = async () => {
      btnFilterEnabled.value = false;
      runningSearch.value = true;
      context.emit('blockingOn');
      console.log('Form abgesendet');

      setSearchCriteria(searchCriteria);  //Save search criteria to storage
      setCalcCriteria(searchCriteria);  //Set price calculation criteria equal to search criteria and store it
      router.push({ name: 'SearchResults', query: {...searchCriteria}});
    };  

    return { 
      submitForm, toggleHiddenFields, hiddenFields, showHiddenFields, updateSearchCriteria, 
      companiesSelect, selectCompanies,
      regionsSelect, selectRegions,
      startDate, endDate, company, companies, region, regions, adults, children, child1age, child2age, child3age, btnFilterEnabled, runningSearch, 
      todayDate, maxCruiseDate,
      error };
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
/* label {
  display: inline-block;
  width: 100%;
  height: 30px;
  max-width: 150px;
  text-align: left;
  font-weight:bold;
  color: rgb(183, 210, 233);
  background: rgb(4, 72, 132);
  font-size: 14px;
  line-height: 24px;
  padding: 3px 10px;
  border-radius: 10px 0 0 10px;
}
input,
select {
  display: inline-block;
  width: 100%;
  height: 30px;
  background:#fff;
  border: none;
  border-bottom:1px solid #ccc;
  padding: 3px 10px;
  font-size: 16px;
  line-height:30px;
  letter-spacing: 1px;
  color: #000;
} */
.search-form {
  padding: 20px
}
.form-field {
  display: flex;
  margin-bottom: 20px;
}
.form-submit {
  text-align: right;
  margin-top:20px;
}
.btn-submit {
  padding: 10px 20px;
  font-size: 14px;
  letter-spacing: 1px;
}
.more-filters {
  margin: 0;
}
.more-filters span {
  text-decoration: underline;
  cursor: pointer
}
.hiddenFields {
  margin-top:20px;
  display:none;
}
</style>
