/**
 * Several independent helper functions that can be used/included in any component/composable
 */

/**
 * Returns a Date Object to YYYY-MM-DD
 * @param {Date} dateObject Date object to be parsed
 * @returns {String} ISO String of YMD without time
 */
const getYMDDate = (dateObject) => {
  let formattedDate = dateObject.toISOString().split('T')[0];
  return formattedDate;
}

/**
 * Converts a string containing a date in YYYY-MM-DD format to DD.MM.YYYY
 * @param {*} dateString 
 * @returns 
 */
const convertYMDToDMY = (dateString) => {
  let convertedDate = dateString.replace(/(\d{4})-(\d{2})-(\d{2}).*/, "$3.$2.$1");
  return convertedDate;
}

/**
 * Converts given DateTime String (e.g. 2022-02-02 00:00:00) to Date Object
 * @param {String} dateString YYYY-MM-DD H:i:s
 * @returns {Date} Date object
 */
function convertYMDHISToDate(dateString) {
  return new Date(dateString.replace( /(\d{4})-(\d{2})-(\d{2}).*/, "$2/$3/$1"))
}

/**
 * Returns the first child of a given object
 * @param {Object} dataObject Object whos first child Object shall be returned
 * @returns {Object} First child object
 */
const getFirstElementOfObject = (dataObject) => {
  let firstObject = dataObject[Object.keys(dataObject)[0]];
  return firstObject;
}

/**
 * Generates a url-conform string from a given string
 * @param {String} str String to be 'urlified'
 * @returns {String} Generated slug
 */
const generateSlug = (str) => {
  return str.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

/**
 * Stores the passed searchCriteria object as JSON-string in the browser's session storage
 * @param {Object} searchCriteria 
 * @returns {Boolean} true
 */
const setSearchCriteria = (searchCriteria) => {
  sessionStorage.setItem('searchCriteria', JSON.stringify(searchCriteria));
  return true
}

/**
 * Reads and parses the JSON-string containing the searchCriteria object from the browser's session storage
 * @returns {Object|Boolean} searchCriteria Object or false if data is not set or not a valid JSON-string
 */
const getSearchCriteria = () => {
  let searchCriteria = sessionStorage.getItem('searchCriteria');
  if(isJson(searchCriteria)) {
    let searchCriteriaParsed = JSON.parse(searchCriteria);

    if(searchCriteriaParsed===null) {
      let now = new Date();
      searchCriteriaParsed =  {};
      searchCriteriaParsed.from = getYMDDate(now);
      searchCriteriaParsed.to = getYMDDate(new Date(now.setMonth(now.getMonth()+3))); //now + 3 months
      searchCriteriaParsed.adults = process.env.VUE_APP_SEEREISEN_DE_DEFAULT_SEARCHCRITERIA_ADULTS; //2
      searchCriteriaParsed.children = process.env.VUE_APP_SEEREISEN_DE_DEFAULT_SEARCHCRITERIA_CHILDREN; //0
      searchCriteriaParsed.child1age = process.env.VUE_APP_SEEREISEN_DE_DEFAULT_SEARCHCRITERIA_CHILD1AGE; //10
      searchCriteriaParsed.child2age = process.env.VUE_APP_SEEREISEN_DE_DEFAULT_SEARCHCRITERIA_CHILD2AGE; //10
      searchCriteriaParsed.child3age = process.env.VUE_APP_SEEREISEN_DE_DEFAULT_SEARCHCRITERIA_CHILD3AGE; //10
      searchCriteriaParsed.junior = process.env.VUE_APP_SEEREISEN_DE_DEFAULT_SEARCHCRITERIA_JUNIOR; //0
      searchCriteriaParsed.region = (process.env.VUE_APP_SEEREISEN_DE_DEFAULT_SEARCHCRITERIA_REGION == 'ALL') ? ['ALL'] : process.env.VUE_APP_SEEREISEN_DE_DEFAULT_SEARCHCRITERIA_REGION; //'ALL'
      searchCriteriaParsed.shippingline = (process.env.VUE_APP_SEEREISEN_DE_DEFAULT_SEARCHCRITERIA_SHIPPINGLINE == 'ALL') ? ['ALL'] : process.env.VUE_APP_SEEREISEN_DE_DEFAULT_SEARCHCRITERIA_SHIPPINGLINE; //'ALL'
    } else { 
      if(!isInt(searchCriteriaParsed.adults)) searchCriteriaParsed.adults = 2;
      if(!isInt(searchCriteriaParsed.children)) searchCriteriaParsed.children = 0;
      if(!isInt(searchCriteriaParsed.child1age) || searchCriteriaParsed.children<1) searchCriteriaParsed.child1age = 10;
      if(!isInt(searchCriteriaParsed.child2age) || searchCriteriaParsed.children<2) searchCriteriaParsed.child2age = 10;
      if(!isInt(searchCriteriaParsed.child3age) || searchCriteriaParsed.children<3) searchCriteriaParsed.child3age = 10;
    }
    setSearchCriteria(searchCriteriaParsed);
    return searchCriteriaParsed;
  } else {
    return false;
  }
}

/**
 * Stores the passed calcCriteria (price calculation criteria) object as JSON-string in the browser's session storage
 * @param {Object} calcCriteria 
 * @returns {Boolean} true
 */
 const setCalcCriteria = (calcCriteria) => {
  sessionStorage.setItem('calcCriteria', JSON.stringify(calcCriteria));
  return true;
}

/**
 * Reads and parses the JSON-string containing the calcCriteria object from the browser's session storage
 * @returns {Object|Boolean} calcCriteria Object or false if data is not set or not a valid JSON-string
 */
 const getCalcCriteria = () => {
  let calcCriteria = sessionStorage.getItem('calcCriteria');
  if(isJson(calcCriteria)) {
    let calcCriteriaParsed = JSON.parse(calcCriteria);

    if(calcCriteriaParsed===null) {
      //Setting standard values (TODO: Read standard values from global config)
      calcCriteriaParsed =  {};
      calcCriteriaParsed.adults = process.env.VUE_APP_SEEREISEN_DE_DEFAULT_CALCCRITERIA_ADULTS; //2
      calcCriteriaParsed.children = process.env.VUE_APP_SEEREISEN_DE_DEFAULT_CALCCRITERIA_CHILDREN; //0
      calcCriteriaParsed.child1age = process.env.VUE_APP_SEEREISEN_DE_DEFAULT_CALCCRITERIA_CHILD1AGE; //0
      calcCriteriaParsed.child2age = process.env.VUE_APP_SEEREISEN_DE_DEFAULT_CALCCRITERIA_CHILD2AGE; //0
      calcCriteriaParsed.child3age = process.env.VUE_APP_SEEREISEN_DE_DEFAULT_CALCCRITERIA_CHILD3AGE; //0
      calcCriteriaParsed.region = (process.env.VUE_APP_SEEREISEN_DE_DEFAULT_CALCCRITERIA_REGION == 'ALL') ? ['ALL']: process.env.VUE_APP_SEEREISEN_DE_DEFAULT_CALCCRITERIA_REGION; //'ALL'
      calcCriteriaParsed.shippingline = (process.env.VUE_APP_SEEREISEN_DE_DEFAULT_CALCCRITERIA_SHIPPINGLINE == 'ALL') ? ['ALL'] : process.env.VUE_APP_SEEREISEN_DE_DEFAULT_CALCCRITERIA_SHIPPINGLINE ; //'ALL'
      calcCriteriaParsed.departureAirport = process.env.VUE_APP_SEEREISEN_DE_DEFAULT_CALCCRITERIA_DEPARTUREAIRPORT; //''
    } else { 
      if(!isInt(calcCriteriaParsed.adults)) calcCriteriaParsed.adults = 2;
      if(!isInt(calcCriteriaParsed.children)) calcCriteriaParsed.children = 0;
      if(!isInt(calcCriteriaParsed.child1age) || calcCriteriaParsed.children<1) calcCriteriaParsed.child1age = 0;
      if(!isInt(calcCriteriaParsed.child2age) || calcCriteriaParsed.children<2) calcCriteriaParsed.child2age = 0;
      if(!isInt(calcCriteriaParsed.child3age) || calcCriteriaParsed.children<3) calcCriteriaParsed.child3age = 0;
    }
    setCalcCriteria(calcCriteriaParsed);
    return calcCriteriaParsed;
  } else {
    return false;
  }
};

/**
 * Saves the user's personal data (name, address, etc.) to the session storage
 * @param {Object} userData user data object
 * @returns true
 */
const setUserdata = (userData) => {
  sessionStorage.setItem('userData', JSON.stringify(userData));
  return true;
};

/**
 * Gets the user's personal data (name, address, etc.) from the browser's session storage
 * @returns {Object|Boolean} userData Object or false if no data is existing
 */
const getUserData = () => {
  let userData = sessionStorage.getItem('userData');
  if(isJson(userData)) {
    let userDataParsed = JSON.parse(userData);
    return userDataParsed;
  } else {
    return false;
  }
}

/**
 * Saves the user's booking data (oid, baid, cabinAssignment) to the session storage
 * @param {Object} bookingData 
 * @returns true
 */
const setBookingData = (bookingData) => {
  sessionStorage.setItem('bookingData', JSON.stringify(bookingData));
  return true;
};

/**
 * Gets the user's booking data (oid, baid, cabinAssignment) from the browser's session storage
 * @returns {Object|Boolean} bookingData Object or false if no data is existing
 */
const getBookingData = () => {
  let bookingData = sessionStorage.getItem('bookingData');
  if(isJson(bookingData)) {
    let bookingDataParsed = JSON.parse(bookingData);
    return bookingDataParsed;
  } else {
    return false;
  }
};

/**
 * Checks if the given string is a valid JSON-string
 * @param {String} str 
 * @returns {Boolean} true|false
 */
function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

/**
 * Checks if the given string is a valid Integer
 * @param {string} value 
 * @returns {Float} parsed numeric value or false if string is no numeric value
 */
function isInt(value) {
  var x;
  if (isNaN(value)) {
    return false;
  }
  x = parseFloat(value);
  return (x | 0) === x;
}

function isNumber(n) { 
  return !isNaN(parseFloat(n)) && !isNaN(n - 0) 
}

/**
 * Sleep function
 * @param {Integer} msec milliseconds
 * @returns 
 */
function sleep(msec) {
  return new Promise(resolve => setTimeout(resolve, msec));
}

/**
 * Validates a string to be a valid email
 * @param {String} email 
 * @returns {Array|Null} Returns array that contains the passed email, followed by all other matches. Returns null if no match.
 */
const validateEmail = (email) => {
  return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

const helperFunctions = () => {
  return  { 
    getYMDDate, convertYMDToDMY, convertYMDHISToDate, getFirstElementOfObject, generateSlug, 
    setSearchCriteria, getSearchCriteria, 
    setCalcCriteria, getCalcCriteria, 
    setUserdata, getUserData, 
    setBookingData, getBookingData,
    isNumber, sleep, isJson,
    validateEmail
  };
}

export default helperFunctions;