<template>
  <div class="container">
    <div class="ccontainer col-8">
      <div v-if="showLogin">
        <h2>Login</h2>
        <LoginForm @login="enterDashboard" @loginerror="loginError" @verifyemailaddress="showEmailVerificationForm" />
        <p class="mt-2">Noch kein Account? <span class="link link-primary" @click="showLogin = !showLogin">Jetzt registrieren</span>.</p>
      </div>
      <div v-else>
        <h2>Registrierung</h2>
        <SignupForm @signup="showEmailVerificationForm" />
        <p class="mt-2">Bereits registriert? <span class="link link-primary"  @click="showLogin = !showLogin">Jetzt einloggen</span>.</p>
      </div>

      <hr>
      <div class="my-5">
        <p>Sie können Sich auch bequem mit einem bestehenden Konto über einen der folgenden Dienstanbieter bei uns einloggen:</p>
        <div class="d-flex p-1">
          <button @click.prevent="startGoogleLogin" class="btn btn-outline-primary mr-3">
            <img style="margin-bottom:3px; margin-right:5px;width:20px" alt="Login mit Google" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" /> Login mit Google
          </button>
          <button @click.prevent="startFacebookLogin" class="btn btn-outline-primary mr-3">
            <img style="margin-bottom:3px; margin-right:5px;width:20px" alt="Login mit Facebook" src="https://upload.wikimedia.org/wikipedia/commons/9/91/036-facebook.png" /> Login mit Facebook
          </button>  
          <button @click.prevent="startAppleLogin" class="btn btn-outline-primary mr-3">
            <img style="margin-bottom:3px; margin-right:5px;width:20px" alt="Login mit Apple ID" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Apple-logo.png/240px-Apple-logo.png" /> Login mit Apple ID
          </button>                    
        </div>      
      </div>
    </div>
  </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
//Components
import SignupForm from '../components/Login/SignupForm.vue';
import LoginForm from '../components/Login/LoginForm.vue';
import useFirebaseLogon from '../composables/useFirebaseLogon';


export default {
  components: { SignupForm, LoginForm },
  setup() {
    const showLogin = ref(true);
    const router = useRouter();

    const { googleLogin, facebookLogin, appleLogin } = useFirebaseLogon();

    const enterDashboard = () => { 
      router.push({ name: 'Dashboard' });
    };

    const loginError = () => {
      //not used. Errors are currently displayed by LoginForm.vue
    };

    const showEmailVerificationForm = () => {
      router.push({name: "EmailVerification" });
    }

    const startGoogleLogin = () => {
      let response = googleLogin();
      if(response) enterDashboard();
    };   
    
    const startFacebookLogin = () => {
      let response = facebookLogin();
      if(response) enterDashboard();
    };  
    
    const startAppleLogin = () => {
      let response = appleLogin();
      if(response) enterDashboard();
    };      

    return { 
      showLogin, enterDashboard, loginError, showEmailVerificationForm, 
      startGoogleLogin, startFacebookLogin, startAppleLogin
      };
  }
}
</script>

<style>

</style>