<template>
  <header class="header-wrapper" :style="{'backgroundImage': headerBgImage.img, 'backgroundPosition': headerBgImage.pos}">
    <Navbar @toggleLanguage="toggleLanguage" />
  </header>
  <div class="content-wrapper">
    <template v-if="(JWTToken!==null && typeof JWTToken !== 'undefined')">
      <router-view v-slot="{Component}">
        <transition name="route" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
    </template>
    <template v-else>
      <Spinner size="xlarge" />
    </template>
  </div>
  <div class="footer-wrapper">
    <Footer />
  </div>
</template>

<script>
//Modules

//Composables
import languageFunctions from './composables/translations';
import helperFunctions from './composables/helperFunctions';
import useFirebaseLogon from './composables/useFirebaseLogon';
//Components
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import Spinner from './components/Spinner.vue'
import { inject, provide, ref } from '@vue/runtime-core';

  export default {
    components: {Navbar, Footer, Spinner},  
    setup() {
      const { getSearchCriteria, getCalcCriteria } = helperFunctions();      
      const { getLanguage } = languageFunctions();    
      
      const JWTToken = ref(inject('JWTToken'));

      const headerBgImage = ref('');
      let headerImages = [
        {img: 'url(https://media.gettyimages.com/photos/tourist-woman-ready-for-cruise-picture-id1090526354?s=2048x2048)', pos: 'center 38%'},
        {img: 'url(https://media.gettyimages.com/photos/summertime-vacation-picture-id506827872?s=2048x2048)', pos: 'center 32%'},
        {img: 'url(https://media.gettyimages.com/photos/woman-looking-out-to-sea-on-a-cruise-ship-picture-id141670436?s=2048x2048)', pos: 'center center'},
        {img: 'url(https://media.gettyimages.com/photos/caucasian-couple-admiring-view-from-boat-deck-picture-id519516311?s=2048x2048)', pos: 'center center'},
        {img: 'url(https://media.gettyimages.com/photos/couple-leaning-on-rail-of-cruise-ship-looking-at-ocean-picture-id200421910-001?s=2048x2048)', pos: 'center center'},
        {img: 'url(https://media.gettyimages.com/photos/happy-woman-looking-at-sea-from-a-cruise-ship-picture-id487190592?s=2048x2048)', pos: 'center 34%'},
        {img: 'url(https://media.gettyimages.com/photos/deck-chair-on-a-cruise-ship-picture-id155284136?s=2048x2048)', pos: 'center 38%'},
        {img: 'url(https://media.gettyimages.com/photos/amazing-evening-view-of-fira-caldera-volcano-of-santorini-greece-with-picture-id1175210335?s=2048x2048)', pos: 'center 42%'}
      ];
      headerBgImage.value = headerImages[Math.floor(Math.random() * headerImages.length)];

      //Provide standard language for child components
      provide('lang', getLanguage('DE'));

      //Provide initial search Criteria (with default values if not set by existing session storage data)
      provide('searchCriteria', getSearchCriteria());

      //Provide initial price calculation Criteria (with default values if not set by existing session storage data)
      provide('calcCriteria', getCalcCriteria());      

      //WIP: function for language toggling, emitted by Navbar
      const toggleLanguage = (newLang) => {
        console.log('Switched language to: '+newLang.langName);
        provide('lang', newLang);

      };


      const { error, logout, getUser } = useFirebaseLogon();
      const user = getUser();
      provide('user', user);

      return { toggleLanguage, headerBgImage, JWTToken };
    }
  }
</script>

<style>
  #app {
    background: #fff;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 5px 10px 5px rgba(0,0,0,.1);
  }
  .header-wrapper {
    width:100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .content-wrapper {
    max-width: 1200px;
    min-height: 500px;
    margin: 0 auto;
  }
  .footer-wrapper {
    min-height:200px;
    margin:0 auto;
    border-top: 20px solid var(--bs-primary);
    background: rgba(var(--bs-primary-rgb),.1); 
  }
</style>
