<template>
  <div class="header-container">
    <div class="d-flex justify-content-between">
      <div class="header-lang">
        <span class="lang de" :class="{'active': lang.langName=='DE'}" @click="toggleLanguage('DE')">DE</span>
        <span class="lang en" :class="{'active': lang.langName=='EN'}" @click="toggleLanguage('EN')">EN</span>
      </div>
      <div class="header-user">
        <div v-if="user">
          <span class="d-inline-block mx-2">Eingeloggt: <img v-if="user.photoURL" :src="user.photoURL" style="height:20px;width:auto"> {{user.displayName}}</span>
          <button class="btn btn-sm btn-primary" @click="userLogout">Logout</button>
        </div>
        <div v-else>
          <button class="btn btn-sm btn-primary" @click="navigateLogin">Anmeden / Registrieren</button>
        </div>
      </div>      
    </div>
    <div class="header-headline">
      <div class="mainhead">Seereisen.de</div>
      <div class="subhead">Mein Seereise-Portal im Internet</div>
    </div>
    <div class="header-nav">
      <nav class="d-flex flex-wrap">
        <router-link :to="{ name: 'Home' }">Home</router-link>
        <router-link :to="{ name: 'Search' }">Reisen suchen</router-link>
        <router-link v-if="searchCriteria" :to="{ name: 'SearchResults', query: {...searchCriteria}}">Suchergebnisse</router-link>
        <router-link v-if="user" :to="{ name: 'Dashboard' }">Dashboard</router-link>
      </nav>
    </div>
  </div>
</template>

<script>
//Modules
import { inject, ref } from '@vue/runtime-core';
import helperFunctions from '../composables/helperFunctions';
import languageFunctions from '../composables/translations';
import useFirebaseLogon from '../composables/useFirebaseLogon';
import { useRouter } from 'vue-router';

export default {
  emits: ['toggleLanguage'],
  setup(props, context) {
    const { getSearchCriteria } = helperFunctions();
    
    const searchCriteria = getSearchCriteria();

    const lang = ref(inject('lang'));
    const { getLanguage } = languageFunctions();

    const user = ref(inject('user'));
    const router = useRouter();
    
    const toggleLanguage = (newLang) => {
      lang.value = getLanguage(newLang);
      context.emit('toggleLanguage', lang.value);
    };

    const navigateLogin = () => {
      router.push({name: "Login"});
    }

    const { logonError, logout } = useFirebaseLogon();
    const userLogout = async () => {
      await logout()
      if(!logonError.value) {
        console.log('User logged out')
        router.push({ name: 'Home' })
      }
    }

    return { searchCriteria, lang, toggleLanguage, user, userLogout, navigateLogin };
  }
}
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

  .header-container {
    position: relative;
    padding: 0;
    max-width: 1200px;
    margin: 0 auto;
    height:300px;
    min-height:300px;
  }
  .header-headline {
    margin-top: 20px;
  }
  .header-headline .mainhead {
    color: #fff;
    font-size: 100px;
    line-height:1em;
    text-shadow: 0px 3px 3px rgba(0,0,0,.75);
    font-family: 'Lobster', cursive;
    letter-spacing: 1px;
  }
  .header-headline .subhead {
    color: #fff;
    font-size: 24px;
    line-height:1em;
    text-shadow: 0px 2px 2px rgba(0,0,0,.75);
    font-family: 'Lobster', cursive;
    
  }
  .header-user {
    background: rgba(255,255,255,.75);
    border-radius: 0 0 10px 10px;
    padding: 5px 10px;
    box-shadow: 0 3px 3px 3px rgba(0,0,0,.25);
  }
  .header-nav {
    background: rgba(255,255,255,.75);
    border-bottom: 3px solid var(--bs-primary);
    border-radius: 10px 10px 0 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    box-shadow: 0 -3px 10px 3px rgba(0,0,0,.15);
  }
  .header-nav nav {
    padding: 0 20px;
  }
  .header-nav nav a {
    display:block;
    color: #333;
    text-shadow: 0 1px 3px #fff;
    text-decoration: none;
    padding: 10px 10px;
    margin: 0 1px 0 0;
    font-weight: bold;
  }
  .header-nav nav a.router-link-active,
  .header-nav nav a:hover,
  .header-nav nav a:focus,
  .header-nav nav a:active {
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-shadow: 0 1px 3px #000;
    background: rgba(var(--bs-primary-rgb),.75);
  }
  .lang {
    display: inline-block;
    padding: 3px 5px;
    border:1px solid #000;
    border-radius:3px;
    margin:5px;
    cursor:pointer;
    background: rgba(255,255,255,.25);
    font-weight: 600;
  }
  .lang.active {
    background: rgba(var(--bs-primary-rgb),.5);
    color: #fff;
  }
</style>