<template>
  <DashboardNavigation />
  <div class="container">  
    <div class="ccontainer">
      <h2>Willkommen, {{user.displayName}}</h2>

      <transition-group appear name="route" mode="out-in">
        <div v-if="subpage=='userdata'" :key="route.fullPath"><DashboardUserdata /></div>
        <div v-if="subpage=='cruises'" :key="route.fullPath"><DashboardCruises /></div>
        <div v-if="subpage=='pricefinder'" :key="route.fullPath"><DashboardPricefinder /></div>
        <div v-if="subpage=='cabinfinder'" :key="route.fullPath"><DashboardCabinfinder /></div>
      </transition-group>
      <!-- <pre style="white-space: break-spaces; word-wrap: anywhere;">User-Objekt: {{user}}</pre> -->
    </div>
  </div>
</template>

<script>
//Modules
import { inject, onUpdated, ref, watch } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router'
//Components
import DashboardNavigation from '../components/Dashboard/DashboardNavigation.vue';
import DashboardUserdata from '../components/Dashboard/DashboardUserdata.vue';
import DashboardCruises from '../components/Dashboard/DashboardCruises.vue';
import DashboardPricefinder from '../components/Dashboard/DashboardPricefinder.vue';
import DashboardCabinfinder from '../components/Dashboard/DashboardCabinfinder.vue';
//Composables

export default {
components: {DashboardNavigation, DashboardUserdata, DashboardCruises, DashboardPricefinder, DashboardCabinfinder},
props: ['subpage'],
setup(props, context) {

    const user = ref(inject('user'));
    
    const router = useRouter();
    const route = useRoute();
    const subpage = ref(route.params.subpage);

    onUpdated(() => {
      subpage.value = route.params.subpage;

    })

    watch(user, () => {
      if(!user.value) {
        router.push({ name: 'Home' })
      }
    })       

    return { user, route, subpage }
  }
}
</script>

<style>

</style>