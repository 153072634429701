<template>
  <div class="side-bar quick-filter">
    <h4 class="sectionHead m-0">Filter</h4>
    <div class="sectionContent py-0">
      <div v-for="fCat in allFilters" :key="fCat.categoryId" class="mb-3">
        <span class="f-cat-name">{{fCat.categoryName}}</span>
        <div v-for="f in fCat.filters" :key="f.filterId" class="cat-group">
          <!--
            filterType		Feldtyp
            0							Checkbox (Boolean i.d. Regel immer =0)
            1							RadioButtons (Boolean wird nicht berücksichtigt)
            2							Select (wenn Boolean=0, dann Multi-Select möglich)
            3							Number (min/max-Wert, die als Option mitgegeben werden, Boolean wird nicht berücksichtigt)
            4							Input Text (falls es mal Freitext-Filter gibt, Boolean w.n.b)
            5							SingleRange (Schieberegler mit min/max-Werten, die als Options mitgegeben werden, Boolean w.n.b)
            6							MultiRange (2 Schieberegler, über die eine Wertespanne angegeben werden kann, mit min/max, die als Options mitgegeben werden, Bookean w.n.b)            
            -->
          <legend class="f-name">{{f.filterName}}</legend>
          <div v-if="f.filterType==0">
              <div v-for="opt in f.options" :key="opt.optionId">
                <div v-if="!(f.filterIsBoolean==1 && opt.optionPosition>0)" class="form-check">
                  <input type="checkbox" class="form-check-input mt-0" :id="f.filterId+'_'+opt.optionId" @click="filterResults(f.filterName, opt.optionId)" :checked="opt.optionDefault==1"> 
                  <label lass="form-check-label" :for="f.filterId+'_'+opt.optionId">{{opt.optionName}}</label>
                </div>
              </div>
          </div>
          <div v-if="f.filterType==1">
            <div v-for="opt in f.options" :key="opt.optionId" class="form-check">
              <input type="radio" class="form-check-input mt-0" :name="f.filterName" :id="f.filterId+'_'+opt.optionId" @change="filterResults(f.filterName, opt.optionId)" :checked="opt.optionDefault==1"> 
              <label class="form-check-label" :for="f.filterId+'_'+opt.optionId">{{opt.optionName}}</label>
            </div>
          </div>
          <div v-if="f.filterType==2">
            <select class="form-select" @change="filterResults(f.filterName, '')">
              <option>Bitte wählen...</option>
              <option v-for="opt in f.options" :key="opt.optionId" :id="f.filterId+'_'+opt.optionId" :selected="opt.optionDefault==1">{{opt.optionName}}</option>
            </select>
          </div>          
        </div>    
      </div>
    </div>
  </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity'
//Components

//Composables
import { sortCruiseList, getSortingSettings } from "../../composables/cruiseListSorter";
import useMetaAPI, { getAllFilters } from '../../composables/useMetaAPI'
import { inject, onMounted } from '@vue/runtime-core';

export default {
  props: ['cruises'],
  setup(props, context) {
    let cruiseList = props.cruises

    const JWTToken = inject('JWTToken');
    const error = ref('');
    const allFilters = ref(null);
    const numFilters = ref(0);

    const { MetaApiError, getAllFilters } = useMetaAPI();

    onMounted(() => {
      getAllFilters(JWTToken)
      .then((result) => {
        console.log('Filters:', result);
        if(result) {
            allFilters.value = result.filterCategories;
            numFilters.value = result.filterCategoriesCount;
        } else {
            error.value += MetaApiError;
        }
      })
      .catch((err) => { console.log(err), error.value += MetaApiError });
    });
    
    /**
     * TODO !!
     */
    const filterResults = (filterBy, filterValue) => {
      // console.log('Filter:', filterBy, filterValue)
      // console.log('CruisesList before filter', cruiseList)
      let sortingSettings = getSortingSettings();
      cruiseList = sortCruiseList(cruiseList, sortingSettings.sortBy, { /* Filter settings here */ });  //Fire cruiseListSorter sorting/filter function

      context.emit('emitCruisesFiltered', cruiseList);
    };
    
    return { filterResults, allFilters, numFilters };
  }    
}
</script>
  
<style>
  .f-cat-name {
    display:block;
    margin:0 -10px;
    padding: 5px 10px;
    font-size: 16px;
    font-weight:600;
    background: rgba(var(--bs-primary-rgb),.1);
    
  }
  legend.f-name {
    margin:10px 0;
    font-size: 16px;
    font-weight:500;
    text-decoration: underline;
  }
</style>