<template>
<div v-if="showPriceTypes.includes(filterId)">
  <h3>{{cabinsListTitle}}</h3>

  <div v-if="pricesWithFlight==1 && flightPrices != null">
    <div><b>Bitte Flughafen wählen:</b></div>
    <div class="input-group p-2 mb-1 bg-light" v-if="flightPrices">
      <span class="input-group-text" id="departure_from">Abflug ab</span>
      <select name="airportSelected" v-model="airportSelected" id="airportSelected" class="form-select" @change="updateCabinsWithFlightPrices()">
        <option value="0">Günstigstem Abflug-Flughafen</option>
        <option v-for="fp in flightPricesSelect" :key="fp" :value="fp.airport + fp.pmc">{{fp.airport}} - {{fp.pmc}}</option>
      </select>   
      <span class="input-group-text" id="flight_class">Flugtarif</span>
      <select name="flightClass" v-model="flightClassSelected" id="flightClass" class="form-select" @change="updateCabinsWithFlightPrices()" >
        <option v-for="fc in flightClasses" :key="fc.ccClass" :value="fc.ccClass">{{fc.ccClass}}</option>
      </select>                     
    </div>
  </div>

  <div class="accordion" :id="'accPrices_'+filterId"> 
    <div v-for="cruiseByCabinType in cruisePricesForCabins" :key="cruiseByCabinType" class="accordion-item">
      <div v-if="showCabinTypes.includes(cruiseByCabinType.cabinType)" class="accordion-header" :id="'heading'+cruiseByCabinType.selector">
        <button class="accordion-button" :class="{'collapsed': cruiseByCabinType.num!=numTabOpened}" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+cruiseByCabinType.selector" :aria-expanded="cruiseByCabinType.num==numTabOpened" :aria-controls="'collapse'+cruiseByCabinType.selector">
          <b>{{cruiseByCabinType.tabName}} für {{travelingPersonsText}}</b>
        </button>          
      </div>
      <div v-if="showCabinTypes.includes(cruiseByCabinType.cabinType)" :id="'collapse'+cruiseByCabinType.selector" class="accordion-collapse collapse" :class="{'show': cruiseByCabinType.num==numTabOpened}" :aria-labelledby="'heading'+cruiseByCabinType.selector" :data-bs-parent="'accPrices_'+filterId">
        <div class="accordion-body">          
          <transition-group appear @before-enter="beforeEnterCabins" @enter="enterCabins" tag="div">
            <div v-for="(c, index) in cruiseByCabinType.prices" :key="c" class="accordion-item" :id="'acc-item-'+c.uniqueFrontendId" :data-index="index">
              <div class="d-flex justify-content-between align-items-stretch">
                <div  class="p-2">
                  <!--
                  <i class="far fa-calendar-alt"></i> Reisetermin: {{c.startDateFormatted}} - {{c.endDateFormatted}}<br>
                  <i class="fa fa-fw fa-building"></i> Veranstalter: {{c.brand}}<br><br>
                  -->
                  <i class="fa fa-fw fa-flag text-primary"></i> Starthafen: {{c.startDestination}}<br>
                  <i class="fa fa-fw fa-flag-checkered text-primary"></i> Zielhafen: {{c.arrivalDestination}}<br>
                  <i class="fa fa-fw fa-bed text-primary"></i> Kabinentyp: {{c.cabinTypeText}} [{{c.cabin}}]<br>
                  Price Model Code: {{c.pmc}}<br>
                  <!-- 
                  Minimale Kabinenbelegung: {{c.minOccupancy}}<br>
                  Maximale Kabinenbelegung: {{c.maxOccupancy}}<br>
                  Cruise-only: {{c.cruiseOnly}}<br>
                  -->
                </div>
                <div  class="p-2">
                  <div v-if="pricesWithFlight==1">
                    <b>Hinflug</b><br>
                    <i class="fas fa-plane-departure text-primary"></i> Start-Flughafen:&nbsp;{{c.flightStartAirport}}<br>
                    <i class="fas fa-plane-arrival text-primary"></i> Ziel-Flughafen:&nbsp;{{c.flightStartDestinationAirport}}<br>
                    <b>Rückflug</b><br>
                    <i class="fas fa-plane-departure text-primary"></i> Start-Flughafen:&nbsp;{{c.flightReturnDestinationAirport}}<br>
                    <i class="fas fa-plane-arrival text-primary"></i> Ziel-Flughafen:&nbsp;{{c.flightReturnStartAirport}}<br>
                  </div>
                </div>
                <div  class="p-2">
                  <div v-if="pricesWithFlight==0" :set="thisTotalPrice = pricesCalculated(c).totalPrice">
                    <span class="text-success"><b>Gesamtpreis: {{pricesCalculated(c).totalPrice}} &euro;</b></span>
                    <br><br>
                    <b>Einzelpreise:</b><br>
                    Erwachsene(r): <span v-for="priceAdult in pricesCalculated(c).adultPrice" :key="priceAdult">{{priceAdult}} &euro;, </span><br>
                    Kind(er): <span v-for="priceChild in pricesCalculated(c).childPrice" :key="priceChild">{{priceChild}} &euro;, </span>
                  </div>
                  <div v-else :set="thisTotalPrice = pricesCalculatedWithFlight(c).totalPrice">
                    <span class="text-success"><b>Gesamtpreis inkl. Flug: {{pricesCalculatedWithFlight(c).totalPrice}} &euro;</b></span>
                    <br><br>
                    <b>Einzelpreise inkl. Flug:</b><br>
                    Erwachsene(r): <span v-for="priceAdult in pricesCalculatedWithFlight(c).adultPrice" :key="priceAdult">{{priceAdult}} &euro;, </span><br>
                    Kind(er): <span v-for="priceChild in pricesCalculatedWithFlight(c).childPrice" :key="priceChild">{{priceChild}} &euro;, </span>
                  </div>
                </div>
                <div class="p-2" :id="'btn-container-'+c.uniqueFrontendId">
                  <div v-if="typeof cabinVacancy[c.uniqueFrontendId] ==='object'" class="cabin-status available">
                    <div v-if=" cabinVacancy[c.uniqueFrontendId].totalPrice != thisTotalPrice">
                      Achtung!! Zwischenzeitlich hat sich der Reisepreis geändert!
                    </div>
                    <div v-if="cabinVacancy[c.uniqueFrontendId].cabinAssignment==1">
                      <button class="btn btn-success fw-bold spin" :id="'btnChooseCabin'+c.uniqueFrontendId" @click.prevent="sendBookingRequest(uniqueTravelId, cabinVacancy[c.uniqueFrontendId].baid, cabinVacancy[c.uniqueFrontendId].oid, 1, c.pmc, c.cabin, c.cabinType)">
                        Kabine wählen und buchen!
                      </button>
                    </div>
                    <div v-else>
                      <button class="btn btn-success fw-bold spin" :id="'btnSendBookingRequest'+c.uniqueFrontendId" @click.prevent="sendBookingRequest(uniqueTravelId, cabinVacancy[c.uniqueFrontendId].baid, cabinVacancy[c.uniqueFrontendId].oid, 0, c.pmc, c.cabin, c.cabinType)">
                        Jetzt buchen!
                      </button>                    
                    </div>
                  </div>
                  <div v-else-if="cabinVacancy[c.uniqueFrontendId]===false" class="cabin-status unavailable">
                    KABINE IST NICHT MEHR VERFÜGBAR
                  </div>
                  <div v-else>
                    <button class="btn btn-primary spin" :id="'btnCheckVacancy'+c.uniqueFrontendId" :disabled="checkingVacancy" @click.prevent="checkVacancy(c.uniqueFrontendId, c.pmc, c.cabin, c.cabinType)">
                      <span v-if="checkingVacancyFor==c.uniqueFrontendId">
                        Prüfe Verfügbarkeit...&nbsp;<Spinner :size="'tiny'" :color="'#fff'" />
                      </span>                  
                      <span v-else>Verfügbarkeit prüfen</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="p-2 bg-light">
                <i class="fa fa-fw fa-exclamation-triangle text-danger"></i> {{c.promoCodeDescription}}
              </div>
            </div>
          </transition-group>          
        </div>
      </div>
    </div>
  </div>  
</div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity';
import { computed, onBeforeMount, onMounted, onUpdated, ssrContextKey } from '@vue/runtime-core';
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import gsap from 'gsap';
//Components
import Spinner from '../Spinner.vue';
//Composables
import useBookingAPI from '../../composables/useBookingAPI'
import {calculatePriceCruiseOnly, calculatePriceCruiseWithFlight} from '../../composables/cruiseDetailsParser'
import helperFunctions from '../../composables/helperFunctions';

export default {
  components: { Spinner },
  props: ['uniqueTravelId', 'pricesWithFlight', 'flightPrices', 'flightClassSelected', 'cruisePricesForCabins', 'cabinsListTitle', 'travelingPersonsText', 'numTabOpened', 'filterId'],
  setup(props, context) {
    const lang = inject('lang');
    const JWTToken = ref(inject('JWTToken'));

    const router = useRouter();

    const cruisePricesForCabins = ref({});
    const cabinsListTitle = ref('');
    const travelingPersonsText = ref('');
    const numTabOpened = ref(0);
    const filterId = ref('');

    const uniqueTravelId = ref(props.uniqueTravelId);

    const { getCalcCriteria, setBookingData } = helperFunctions();

    let calcCriteria = getCalcCriteria();

    const pricesWithFlight = ref(props.pricesWithFlight);
    
    const flightPrices = ref(props.flightPrices); //contains only prices for the selected departure airport
    const airportSelected = ref('0');
    const flightClassSelected = ref(props.flightClassSelected);

    const checkingVacancy = ref(false);
    const checkingVacancyFor = ref(null);

    const cabinVacancy = ref({});

    const bookingRequested = ref(false);
    const bookingRequestedFor = ref(null);

    const showCabinTypes = inject('showCabinTypes'); 
    const showPriceTypes = inject('showPriceTypes');

    const { BookingApiError, getVacancy } = useBookingAPI();

    const flightPricesSelect = ref({});
    const flightClasses = ref({});
    


    const pricesCalculated = (pricesArray) => {
      let arChildren = [];
      if(calcCriteria.children>0) arChildren.push(calcCriteria.child1age);
      if(calcCriteria.children>1) arChildren.push(calcCriteria.child2age);
      if(calcCriteria.children>2) arChildren.push(calcCriteria.child3age);
      return calculatePriceCruiseOnly(pricesArray, calcCriteria.adults, arChildren);
    };

    const pricesCalculatedWithFlight = (pricesArray) => {
      let arChildren = [];
      if(calcCriteria.children>0) arChildren.push(calcCriteria.child1age);
      if(calcCriteria.children>1) arChildren.push(calcCriteria.child2age);
      if(calcCriteria.children>2) arChildren.push(calcCriteria.child3age);
      return calculatePriceCruiseWithFlight(pricesArray, calcCriteria.adults, arChildren);
    };    

    onMounted(() => {
      calcCriteria = getCalcCriteria();
      cruisePricesForCabins.value = props.cruisePricesForCabins;
      cabinsListTitle.value = props.cabinsListTitle;
      travelingPersonsText.value = props.travelingPersonsText;
      numTabOpened.value = props.numTabOpened;  //Shows the corresponding accordion item opened by default
      filterId.value = props.filterId;

      // flightPrices.value = props.flightPrices; //contains only prices for the selected departure airport

      if(flightPrices.value !== null) {
        flightPrices.value.forEach((key, index) => {
          flightPricesSelect.value[flightPrices.value[index].ccStartAirport + flightPrices.value[index].ccCode] = {airport: flightPrices.value[index].ccStartAirport, pmc: flightPrices.value[index].ccCode};          
          flightClasses.value[flightPrices.value[index].ccClass] = {ccClass: flightPrices.value[index].ccClass};          
        });       
      }
            
    });

    onUpdated(() => {
      calcCriteria = getCalcCriteria();
      cruisePricesForCabins.value = props.cruisePricesForCabins;
      cabinsListTitle.value = props.cabinsListTitle;
      travelingPersonsText.value = props.travelingPersonsText;
      cabinVacancy.value = {};

      // flightPrices.value = props.flightPrices; //contains only prices for the selected departure airport
      flightClassSelected.value = props.flightClassSelected;  //selected ccClass (Y, B, P)

      // if(flightPrices.value !== null) {
      //   flightPrices.value.forEach((key, index) => {
      //     flightPricesSelect.value[flightPrices.value[index].ccStartAirport + flightPrices.value[index].ccCode] = {airport: flightPrices.value[index].ccStartAirport, pmc: flightPrices.value[index].ccCode};          
      //     flightClasses.value[flightPrices.value[index].ccClass] = {ccClass: flightPrices.value[index].ccClass};          
      //   });       
      // }
    });

    //The flight prices for the selected departure airport that will be passed to the child component(s) (e.g. CruiseCabinsList.vue)
    const updateCabinsWithFlightPrices = () => {
      context.emit('updateCabinsWithFlightPrices', airportSelected.value, flightClassSelected.value);
    };    

    /**
     * Checks the vacancy of a specific travel's cabin/pmc
     */
    const checkVacancy = (uniqueFrontendId, pmc, cabin, cabinType) => {
      checkingVacancy.value = true;
      checkingVacancyFor.value = uniqueFrontendId;
      if(JWTToken.value !== null) {
        console.log('Checking Vacancy of cabin...',uniqueTravelId.value, pmc, cabin, cabinType, pricesWithFlight.value, airportSelected.value.substr(0,3), flightClassSelected.value, calcCriteria.adults, calcCriteria.child1age, calcCriteria.child2age, calcCriteria.child3age);
        getVacancy(uniqueTravelId.value, pmc, cabin, cabinType, pricesWithFlight.value, airportSelected.value.substr(0,3), flightClassSelected.value, calcCriteria.adults, calcCriteria.child1age, calcCriteria.child2age, calcCriteria.child3age, JWTToken.value)
          .then((result) => {
            console.log(result);
            if(result) {
              cabinVacancy.value[uniqueFrontendId] = result;
            } else {
              cabinVacancy.value[uniqueFrontendId] = false;
            }
            // console.log(cabinVacancy.value);
          })
          .catch((err) => { console.log(err), error.value += BookingApiError })
          .finally(() => { 
            checkingVacancy.value = false;
            checkingVacancyFor.value = null;
          });
      } else {
        router.push({name:'Home'});
      }
    };

    const sendBookingRequest = (uniqueTravelId, baid, oid, chooseCabin, pmc, cabin, cabinType) => {
      let bookingData = { uniqueTravelId: uniqueTravelId, oid: oid, baid: baid, chooseCabin: chooseCabin, pmc: pmc, cabin: cabin, cabinType: cabinType };
      setBookingData(bookingData);
      router.push({name:'Booking', params: { 'uniqueTravelId': uniqueTravelId }});
    };

    const beforeEnterCabins = (el) => {
      el.style.opacity = 0;
      el.style.transform = 'translateX(100px)';
      el.style.backgroundColor = '#2777bd';
    }
    const enterCabins = (el, done) => {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        backgroundColor: 'transparent',
        duration: 0.3,
        onComplete: done,
        delay: el.dataset.index * 0.2 //gets the index of the element (the cruise) from the :data-index="index"
      })
    }

    return {
      uniqueTravelId,
      showCabinTypes, showPriceTypes, 
      beforeEnterCabins, enterCabins,
      updateCabinsWithFlightPrices, airportSelected, flightPricesSelect, flightClasses, flightClassSelected,
      pricesWithFlight, flightPrices, cruisePricesForCabins, cabinsListTitle, travelingPersonsText, numTabOpened, filterId,
      pricesCalculated, pricesCalculatedWithFlight, 
      checkVacancy, checkingVacancy, checkingVacancyFor, cabinVacancy, 
      sendBookingRequest
      };
  }
}
</script>

<style scoped>
.accordion-header {
  background: rgba(var(--bs-primary-rgb),.5);
  color: rgb(4, 72, 132);
}
.accordion-button {
  background: rgba(var(--bs-primary-rgb),0);
  color: rgb(4, 72, 132);
}
.cabin-status {
  font-weight:bold;
}

.cabin-status.available {
  color: var(--bs-success);
}

.cabin-status.unavailable {
  color: var(--bs-danger);
}
.cabin-status.unavailable:after {
  content: "\f5b3";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}
</style> 