/*
== == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
    @sortCruiseList
    * Sort given cruiseList by filter and direction given as Params.
    ! If no filter or direction are given, will sort by Date and in ASC direction.
    ? Possibly more sorting filters to be added later?
    ? Possibly increased tempo of Price sorting required?
    @Params
        {Object} 	cruiseList
        |> List of searchresults
        {String}	sortBy
        |> Optional Parameter: Value to be sorted by.
        {String} 	direction
        |> Optional Parameter: Direction in which the values will be sorted.
        {Object}    filterBy
        |> Optional parameter: Object of key/value pairs that shall be used as filter criteria {ship_brauhaus: 1, region_climate: 'warm', ...}
    @Returns
        {Object}
        |> Sorted list of searchresults
== == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == == ==
*/
export function sortCruiseList(cruiseList, sortBy = 'date', direction = 'asc', filterList = {}) {
    if(cruiseList === 'undefined' || cruiseList === null) {
        return false;
    } else {
        if(sortBy === 'undefined' || sortBy === null) {
            sortBy = 'date';
        }
        if(direction === 'undefined' || direction === null) {
            direction = 'asc';
        }
        const sortable = [];
        const sortedList = {
            totalCruisesCount: cruiseList.totalCruisesCount,
            cruises: {}, 
        };
        Object.values(cruiseList.cruises).forEach((cruise) => {
            cruise.filters = {1: Math.round(Math.random()), 2: Math.round(Math.random()), 3: Math.round(Math.random()), 4: Math.round(Math.random()), 5: Math.round(Math.random())};
            sortable.push(cruise);
        });

        sortingSettings(sortBy, direction);
    
        switch(sortBy) {
            case 'date':
                if(direction === 'desc') { 
                    sortable.sort(
                        (a, b) => (Date.parse(Object.values(a.departures)[0].routeScheduleStart) < Date.parse(Object.values(b.departures)[0].routeScheduleStart)) ? 1 
                                : (Date.parse(Object.values(b.departures)[0].routeScheduleStart) < Date.parse(Object.values(a.departures)[0].routeScheduleStart)) ? -1 
                                : 0
                    );
                } else {
                    sortable.sort(
                        (a, b) => (Date.parse(Object.values(a.departures)[0].routeScheduleStart) > Date.parse(Object.values(b.departures)[0].routeScheduleStart)) ? 1 
                                : (Date.parse(Object.values(b.departures)[0].routeScheduleStart) > Date.parse(Object.values(a.departures)[0].routeScheduleStart)) ? -1 
                                : 0
                    );
                }
                break;
            case 'name':
                if(direction === 'desc') {
                    sortable.sort(
                        (a, b) => (a.routeName.toLowerCase() < b.routeName.toLowerCase()) ? 1 
                                : (b.routeName.toLowerCase() < a.routeName.toLowerCase()) ? -1
                                : 0
                    );
                } else {
                    sortable.sort(
                        (a, b) => (a.routeName.toLowerCase() > b.routeName.toLowerCase()) ? 1 
                                : (b.routeName.toLowerCase() > a.routeName.toLowerCase()) ? -1 
                                : 0
                    );
                }
                break; 
            case 'price':
                sortable.sort(
                    (a, b) => {
                        let pricesA = [];
                        Object.values(a.departures).forEach((departure) => {
                            pricesA.push(departure.cheapestPrice);
                        });
                        let cheapA = Math.min(...pricesA);
    
                        let pricesB = [];
                        Object.values(b.departures).forEach((departure) => {
                            pricesB.push(departure.cheapestPrice);
                        });
                        let cheapB = Math.min(...pricesB);
    
                        if(direction === 'desc') {
                            if(cheapA < cheapB) {
                                return 1;
                            } else if(cheapB < cheapA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } else {
                            if(cheapA > cheapB) {
                                return 1;
                            } else if(cheapB > cheapA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        }
                    }
                );
                break;
        }
    
        sortable.forEach((cruise, index) => {
            sortedList.cruises[index] = cruise;
        });

        return sortedList;
    }
}

export function applyFilters(cruiseList, filterList = {1: 1, 2: 1, 3: 0, 4: 1}) {
    let ff = Object.entries(filterList);
    let cf = Object.entries(cruiseList.cruises);
    let tempCl = {
        totalCruisesCount: 0,
        cruises: {}
    };

    let conversionArray = [];

    cf.forEach((singleCruise) => {
        let c = 0;
        Object.entries(singleCruise[1].filters).forEach((singleFilter) => {
            ff.forEach((filter) => {
                if(singleFilter[0] === filter[0]) {
                    if(singleFilter[1] === filter[1]) {
                        c++;
                    }
                }
            })
        });

        if(c >= ff.length) {
            conversionArray.push(singleCruise[1]);
        }
    });
    
    Object.assign(tempCl.cruises, conversionArray);
    tempCl.totalCruisesCount = conversionArray.length;

    return tempCl;
}

export function sortingSettings(sortBy = null, sortDir = null) {
    if(sortBy === null && sortDir === null) {
        return  { 
            sortBy: (typeof sessionStorage.getItem('sortBy') !== 'undefined') ? sessionStorage.getItem('sortBy') : 'date',
            sortDir: (typeof sessionStorage.getItem('sortDir') !== 'undefined') ? sessionStorage.getItem('sortDir') : 'asc'
        };
    } else {
        if(sortBy !== null && sortBy !== 'undefined') {
            sessionStorage.setItem('sortBy', sortBy);
        }

        if(sortDir !== null && sortDir !== 'undefined') {
            sessionStorage.setItem('sortDir', sortDir);
        }

        if((sortBy !== null && sortBy !== 'undefined') && (sortDir !== null && sortDir !== 'undefined')) {
            return true;
        } else {
            return false;
        }
    }
}

export function getSortingSettings() {
    let sortBy = (typeof sessionStorage.getItem('sortBy') !== 'undefined') ? sessionStorage.getItem('sortBy') : 'date';
    let sortDir = (typeof sessionStorage.getItem('sortDir') !== 'undefined') ? sessionStorage.getItem('sortDir') : 'asc';
    return { sortBy: sortBy, sortDir: sortDir }
}

export function setSortingSettings(sortBy, sortDir) {
    sessionStorage.setItem('sortBy', sortBy);
    sessionStorage.setItem('sortDir', sortDir);
    return true;
}