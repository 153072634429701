<template>
  <div class="sorting-buttons mb-2">
    <div class="input-group">
      <button class="form-control btn btn-secondary" :class="{'active': sortingSettings.sortBy=='date'}" @click="sort('date')">Datum <i :class="{'fa-sort-numeric-up-alt': sortDir['date']=='desc', 'fa-sort-numeric-down': sortDir['date']=='asc'}" class="fas"></i></button>
      <button class="form-control btn btn-secondary" :class="{'active': sortingSettings.sortBy=='name'}" @click="sort('name')">Reisename <i :class="{'fa-sort-alpha-up-alt': sortDir['name']=='desc', 'fa-sort-alpha-down': sortDir['name']=='asc'}" class="fas"></i></button>
      <button class="form-control btn btn-secondary" :class="{'active': sortingSettings.sortBy=='price'}" @click="sort('price')">Preis <i :class="{'fa-sort-numeric-up-alt': sortDir['price']=='desc', 'fa-sort-numeric-down': sortDir['price']=='asc'}" class="fas"></i></button>
    </div>
  </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity';
//Composables
import { sortCruiseList, getSortingSettings } from '../../composables/cruiseListSorter';

export default {
  props: ['cruiseList'],
  setup(props, context) {

    const sortDir = ref({'date': 'asc', 'name': 'asc', 'price': 'asc'});  //Standard-Sortings when nothing is sorted yet
    const sortingSettings = ref(getSortingSettings());

    if(sortDir.value.hasOwnProperty(sortingSettings.value.sortBy)) {
      sortDir.value[sortingSettings.value.sortBy] = sortingSettings.value.sortDir;  //Overwrite default orderdirection with current user-selected orderdirection, if set
    }

    let cruiseList = props.cruiseList;

    const sort = (sortKey) => {
      sortDir.value[sortKey] = (sortDir.value[sortKey]=='asc') ? 'desc' : 'asc';  //Toggle sortdirection on Button-Click
      sortingSettings.value.sortBy = sortKey;
      sortingSettings.value.sortDir = sortDir.value[sortKey];
      console.log('sorting by:',sortKey, sortDir.value[sortKey])
      cruiseList = sortCruiseList(cruiseList, sortKey, sortDir.value[sortKey]);
      context.emit('updateCruiseList', cruiseList);
    };

    const applyFilter = () => {
      console.log(applyFilters(cruiseList));
      context.emit('updateCruiseList', applyFilters(cruiseList));
    }

    return {applyFilter, sort, sortDir, sortingSettings} 
  }
}
</script>

<style>
.sorting-buttons {
  /* background: #efefef; */
}
.sorting-buttons .btn.active {
  background-color:var(--bs-active-bg) !important;
  border-color: var(--bs-active-border) !important;
}
</style>