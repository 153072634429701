<template>
  <div class="dashboard-userdata">
    <h3>Kontaktdaten &amp; Mitreisende</h3>
    <p class="introtext">
      Nachfolgend können Sie Ihre Kontaktdaten angeben, damit diese im Buchungsprozess direkt für Sie an entsprechender Stelle eingesetzt werden.
    </p>

    <div class="registrant-data">
      <form @submit.prevent="storeUserdata">
        <div class="form-area mb-5 p-3">
          <h3>Ihre Kontaktdaten</h3>
          <div class="legend mb-3">
            <p>
              Bitte geben Sie hier Ihre Kontaktdaten an.<br>
              Diese Daten benötigen wir, wenn Sie eine Reise buchen möchten und im Preis- oder Kabinenfinder einen Suchauftrag speichern möchten.<br>
              Alle mit <span class="required"></span> markierten Felder benötigen wir, um Ihnen einen perfekten Service bieten zu können.
            </p>
          </div>
          <div class="form-fields">
            <div class="input-group mb-3">
              <span class="input-group-text required">Anrede</span>
              <select class="form-select" v-model="userData.title" id="registrant_title" required>
                  <option value="Herr">Herr</option>
                  <option value="Frau">Frau</option>
                  <option value="Divers">Divers</option>
              </select>
              <template v-if="userData.title=='Divers'">
                <span class="input-group-text required">Anrede für Reiseveranstalter*</span>
                <select class="form-select" v-model="userData.birthTitle" id="registrant_birthtitle" required>
                    <option value="">- Bitte wählen Sie -</option>
                    <option value="Herr">Herr</option>
                    <option value="Frau">Frau</option>
                </select>
              </template>
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text required">Name</span>
              <input class="form-control" type="text" v-model="userData.firstname" id="registrant_firstname" placeholder="Vorname" required>
              <input class="form-control" type="text" v-model="userData.lastname" id="registrant_lastname" placeholder="Nachname" required>
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text required">Anschrift</span>
              <input class="form-control" type="text" v-model="userData.street" id="registrant_street" placeholder="Straße" required>
              <input class="form-control" type="text" v-model="userData.streetnumber" id="registrant_streetnumber" placeholder="Hausnummer" required>
              <input class="form-control" type="text" v-model="userData.zipcode" id="registrant_zipcode" placeholder="Plz" required>
              <input class="form-control" type="text" v-model="userData.city" id="registrant_city" placeholder="Ort" required>                  
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text required">Rufnummer</span>
              <div class="form-control">
                <template v-if="fetchingFormFields"><Spinner :size="'tiny'" /></template>
                <template v-else>
                  <Multiselect v-model="userData.phone_prefix" id="registrant_phone_prefix" :options="allDialcodes" :groups="true" :searchable="true" :close-on-select="true" required />
                </template>
              </div>
              <input class="form-control" type="text" v-model="userData.phone" id="registrant_phone" placeholder="Telefonnummer" required>
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text">Mobilnummer</span>
              <div class="form-control">
                <template v-if="fetchingFormFields"><Spinner :size="'tiny'" /></template>
                <template v-else>
                  <Multiselect v-model="userData.mobile_prefix" id="registrant_mobile_prefix" :options="allDialcodes" :groups="true" :searchable="true" :close-on-select="true" />
                </template>
              </div>
              <input class="form-control" type="text" v-model="userData.mobile" id="registrant_mobile" placeholder="Mobilnummer">
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text required">E-Mail Adresse</span>
              <input class="form-control" type="email" v-model="userData.email" id="registrant_email" placeholder="E-Mail Adresse" required>
            </div>                
          </div>
          <div class="errormsg" v-if="formErrors.userdata.length>0">
            <i class="fa fa-fw fa-exclamation-triangle text-danger"></i> <b>Bitte korrigieren Sie folgende Eingaben:</b>
            <ul>
              <transition-group appear @before-enter="beforeEnterErrors" @enter="enterErrors">
              <li v-for="(msg, index) in formErrors.userdata" :key="index" :data-index="index" v-html="msg"></li>
              </transition-group>
            </ul>
          </div>  

          <div class="form-submit p-3">
            <div v-if="userDataSaved" class="bg-success rounded border-success p-3 mb-3">
              <p class="text-white">Ihre Kontaktdaten wurden erfolgreich gespeichert.</p>
            </div>          
            <div class="d-block d-md-flex justify-content-end">              
              <div class="form-submit">
                <button type="submit" class="btn btn-lg btn-success" :disabled="!btnSubmitActive">Daten überprüfen &amp; Speichern</button>
              </div>
            </div>            
          </div>             
        </div>
      </form>
    </div>
  </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity';
import gsap from 'gsap';
import { inject, onBeforeMount, onUpdated } from '@vue/runtime-core';
//Components
import Spinner from '../Spinner.vue';
import Multiselect from '@vueform/multiselect';
//Composables
import useMetaAPI from '../../composables/useMetaAPI';
import helperFunctions from '../../composables/helperFunctions';
import createFormFields from '../../composables/createFormFields';

export default {
  components: {Multiselect, Spinner},
  setup(props, context) {
    const JWTToken = ref(inject('JWTToken'));
    const user = inject('user');
    
    const userData = ref({});
    const userDataSaved = ref(false);

    const { getYMDDate, setUserdata, getUserData, validateEmail } = helperFunctions();
    const { createFormFieldsError, fetchingFormFields, getCountriesAndDialCodes} = createFormFields();

    const allCountries = ref([]);
    const allDialcodes = ref([]);

    const btnSubmitActive = ref(false);

    const formErrors = ref({userdata:[]});


    onBeforeMount(() => {
      userData.value = getUserData();
      if(!userData.value) {
        userData.value = { 
          title: 'Herr',
          birthTitle: '',
          firstname: '',
          lastname: '',
          street: '',
          streetnumber: '',
          zipcode: '',
          city: '', 
          phone: '',
          phone_prefix: '+49',
          mobile: '',
          mobile_prefix: '+49',
          email: '',
          travelers: Array()
          };
      }

      //get all Countries and DialCodes
      let countriesList = getCountriesAndDialCodes(JWTToken.value);
      allCountries.value = countriesList.countries;       
      allDialcodes.value = countriesList.dialCodes;

    });

    onUpdated(() => {
      validateFormData();
    });

    
    const validateFormData = () => {
      btnSubmitActive.value = false;
      formErrors.value.userdata = [];
      if(userData.value.firstname.length<3) formErrors.value.userdata.push('Bitte geben Sie Ihren Vornamen an.');
      if(userData.value.lastname.length<3) formErrors.value.userdata.push('Bitte geben Sie Ihren Nachnamen an.');
      if(userData.value.title=='Divers' && (userData.value.birthTitle=='' || typeof userData.value.birthTitle == 'undefined')) formErrors.value.userdata.push('Bitte wählen Sie eine Anrede aus, die wir an den Reiseveranstaler weitergeben dürfen.<br>* Die eingeschränkte Wahl zwischen "Herr" und "Frau" geht auf die Reiseveranstalter zurück.');
      if(userData.value.street.length<3) formErrors.value.userdata.push('Bitte geben Sie den Straßennamen Ihrer Wohnanschrift an.');
      if(userData.value.streetnumber.length<1) formErrors.value.userdata.push('Bitte geben Sie die Hausnummer Ihrer Wohnanschrift an.');
      if(userData.value.zipcode.length<5) formErrors.value.userdata.push('Bitte geben Sie die Postleitzahl Ihrer Wohnanschrift an.');
      if(userData.value.city.length<3) formErrors.value.userdata.push('Bitte geben Sie Ihren Wohnort an.');
      if(userData.value.phone.length<9) formErrors.value.userdata.push('Bitte geben Sie Ihre Telefonnummer an, unter der wir Sie zu Zwecken der Reisebuchung kontaktieren dürfen.');
      if(!validateEmail(userData.value.email)) formErrors.value.userdata.push('Bitte geben Sie Ihre E-Mail Adresse an, unter der wir Sie zu Zwecken der Reisebuchung kontaktieren dürfen.');
      
      if(formErrors.value.userdata.length==0) btnSubmitActive.value = true;
    };   
    
    const storeUserdata = () => {
      setUserdata(userData.value);
      userDataSaved.value = true;
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//TODO: USERDATA SPEICHERN ÜBER API
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    }

    const beforeEnterErrors = (el) => {
      el.style.opacity = 0;
      el.style.transform = 'translateX(50px)';
    }
    const enterErrors = (el, done) => {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        duration: 0.2,
        onComplete: done,
        delay: el.dataset.index * 0.2 //gets the index of the element (the cruise) from the :data-index="index"
      })
    }      

    return { formErrors, userData, allDialcodes, btnSubmitActive, storeUserdata, userDataSaved,
    fetchingFormFields,
    beforeEnterErrors, enterErrors
    }
  }
}
</script>

<style>

</style>