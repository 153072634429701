<template>
  <div class="cruise" :id="'route_'+cruise.routeId">
      <div class="cruise-title d-flex justify-content-between">
        <div>
          <h3>{{cruise.routeName}} ({{cruise.routeScheduleDuration}} Nächte)</h3>
          <h4>{{ cruise.shipName }} - {{ cruise.shippingLineName}}</h4>
        </div>
        <div>
          <span class="badge bg-primary cruise-badge urlex" v-if="cruise.dependency=='DUS'">Urlaubsexperte</span>
          <span class="badge bg-danger cruise-badge hanseat" v-if="cruise.dependency=='BRE'">Hanseat</span>
        </div>
      </div>
      <!--
      <div class="d-flex">
        <i class="far fa-calendar-alt"></i>
        <select v-model="selectedDeparture">
          <option v-for="departure in cruise.departures" :key="departure.uniqueTravelId" :value="departure.uniqueTravelId">
            {{departure.routeScheduleStartDate.getDate()}}.{{departure.routeScheduleStartDate.getMonth()}}.{{departure.routeScheduleStartDate.getFullYear()}} - ab {{departure.cheapestPrice}} &euro;
          </option>
        </select>
      </div>
      -->
      <div v-for="departure in cruise.departures" :key="departure.uniqueTravelId">
        <div :id="departure.uniqueTravelId" v-if="selectedDeparture==departure.uniqueTravelId">
          <div class="d-flex py-2">
            <div class="teaser-image-bg">
              <div v-if="departure.teaserImage" class="teaser-image">
                <img :src="departure.teaserImage" :alt="cruise.routeName+' - '+cruise.routeScheduleDuration+' Tage'">
              </div>
              <div v-else class="placeholder-image" :alt="cruise.routeName+' - '+cruise.routeScheduleDuration+' Tage'"></div>
            </div>
            <div class="w-100 p-3">
              <div class="float-end text-center">
                <sub>ab</sub><br><span class="priceTag">{{departure.cheapestPrice}} &euro;</span><br><sup>p.P.</sup>
              </div>
                <div>
                  <i class="fas fa-ship"></i> Günstigster Preis: ab {{departure.cheapestPrice}} &euro;<br>
                </div>              
                <div v-if="cruise.withFlightBookable==1" class="mt-2">
                  <b>Diese Reise ist auch mit Flug buchbar</b><br>
                  <i class="fas fa-plane"></i> Günstigster Preis ab: {{departure.cheapestPriceWithFlight}} &euro;
                </div>  
                <!--
                  <div>
                  <i class="far fa-calendar-alt"></i> {{numVariants}} Termin{{numVariants>1 ? 'e':'' }} verfügbar
                </div>
                -->
            </div>
          </div>
          <div class="d-flex justify-content-between ">
            <div class="calendar-bar d-flex flex-wrap">
              <div v-for="departure in cruise.departures" :key="departure.uniqueTravelId" class="calendar-btn" :class="{selected: selectedDeparture==departure.uniqueTravelId}" @click="selectedDeparture=departure.uniqueTravelId">
                <div class="month">{{departure.routeScheduleStartMonth}}</div>
                <div class="day">{{departure.routeScheduleStartDate.getDate()}}</div>
                <div class="year">{{departure.routeScheduleStartDate.getFullYear()}}</div>
              </div>
            </div> 
            <div>
              <button @click="showCruiseDetails(cruise, departure)" class="btn btn-lg btn-primary">Reisedetails</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { inject } from 'vue'
//Components

//Composables
import helperFunctions from '../../composables/helperFunctions';
import { getSortingSettings } from '../../composables/cruiseListSorter';

export default {
  props: ['cruise'],
  setup(props) {
    const lang = inject('lang');
    
    const { getFirstElementOfObject, generateSlug, convertYMDHISToDate } = helperFunctions();  
    
    const selectedDeparture = ref('');
    const cruise = ref(props.cruise)

    //Gathers information and converts departure dates for all alternate departures of this cruise
    Object.keys(cruise.value.departures).forEach((key) => {
      cruise.value.departures[key].routeScheduleStartDate = convertYMDHISToDate(cruise.value.departures[key].routeScheduleStart) //Converts DateTime-String to Javascript Date Object
      cruise.value.departures[key].routeScheduleStartMonth = lang.monthNamesShort[cruise.value.departures[key].routeScheduleStartDate.getMonth()] //Gets short month name like 'Apr'
    });

    const numVariants = ref(Object.keys(cruise.value.departures).length);
    let firstDeparture = getFirstElementOfObject(props.cruise.departures);
    selectedDeparture.value = firstDeparture.uniqueTravelId;

    const sortingSettings = ref(getSortingSettings());
    if(sortingSettings.value.sortBy == 'price') {
      if(sortingSettings.value.sortDir == 'asc') selectedDeparture.value = cruise.value.cheapestPriceCruiseUniqueTCCTravelIdOverall;  //pre-selects the departure with the cheapest price
    }

    const router = useRouter();
    const showCruiseDetails = (cruise, departure) => {
      var slug = generateSlug(cruise.routeName)
      console.log('Navigating to CruiseDetails:', {uniqueTravelId: departure.uniqueTravelId, slug: slug });
      router.push({ name: 'CruiseDetails', params: {uniqueTravelId: departure.uniqueTravelId, slug: slug }});
    };

    return { showCruiseDetails, selectedDeparture, numVariants };
  }
}
</script>

<style scoped>
  .cruise {
    border-bottom: 1px solid var(--bs-primary);
    border-top: 1px solid var(--bs-primary);
    padding: 10px
  }
  .cruise-title {
    
  }
  .cruise .teaser-image-bg {
    position: relative;  
    display:inline-block;
    width:250px;
    height:auto;
    min-width:250px;
    min-height:150px;
    margin:0;
    padding:0;  
    border:1px solid #ccc;
    background: #ffffff;
  }  
  .cruise .teaser-image-bg:before {
    content: "Lade Vorschaubild...";
    position: absolute;
    display:block;
    width:100%;
    height:30px;
    bottom: 10px;
    left:0;
    text-align: center;
  }
  .cruise .teaser-image {

  }
  .cruise .placeholder-image {
    position: relative;
    width:250px;
    height:auto;
    min-width:250px;
    min-height:150px;
    background: url('https://media.xmlteam.de/t/m/s/aida-cruises/aidabella/schiff/4.jpeg') center center no-repeat;
    background-size: cover;
  }  
  .cruise .priceTag {
    font-size:24px;
    font-weight:bold;
    color:#0c0;
    text-decoration: underline;
    text-decoration-style: double;
  }
</style>