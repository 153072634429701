//Modules
import { createRouter, createWebHistory } from 'vue-router';
// import useJWTToken from '../composables/useJWTAuth';
//Components
import Home from '../views/Home.vue';
import Search from '../views/Search.vue';
import SearchResults from '../views/SearchResults.vue';
import CruiseDetails from '../views/CruiseDetails.vue';
import Booking from '../views/Booking.vue';
import Imprint from '../views/Imprint.vue';
import Login from '../views/Login.vue';
import EmailVerification from '../views/EmailVerification.vue';
import Dashboard from '../views/Dashboard.vue';
import NotFound from '../views/NotFound.vue';
import Error from '../views/Error.vue';

//Old JWTToken fetching, when fetching the token was done everytime before navigating to a new page
// const fetchJWTToken = (to, from, next) => {

//   const { JWTerror, fetchJWTTokenFromAPI, getJWTToken, decodeJWTToken, saveJWTTokenToLocalStorage, initializeJWTToken } = useJWTToken();
//   initializeJWTToken()
//     .then((result) => { 

//       let tokenIsValid = false;
//       let JWTToken = result; 
//       if(JWTToken !== null) tokenIsValid = true;

//       if(!tokenIsValid) {
//         throw new Error('Token invalid!');
//       } else {
//         to.params.JWTToken = JWTToken;
//         next();
//       }      
//     })
//     .catch((err) => {
//       next({ name: 'Home' });
//     });
// }

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/suche',
    name: 'Search',
    component: Search,
    props: true,
    // beforeEnter: fetchJWTToken
  },  
  {
    path: '/suchergebnisse',
    name: 'SearchResults',
    component: SearchResults,
    props: true,
    // beforeEnter: fetchJWTToken
  },    
  {
    path: '/reise-details/:uniqueTravelId/:slug?',
    name: 'CruiseDetails',
    component: CruiseDetails,
    props: true,
    // beforeEnter: fetchJWTToken
  },
  {
    path: '/booking',
    name: 'Booking',
    component: Booking,
    props: true,
    // beforeEnter: fetchJWTToken
  },  
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },  
  {
    path: '/dashboard/:subpage?',
    name: 'Dashboard',
    component: Dashboard,
    props: true
  },       
  {
    path: '/emailverification',
    name: 'EmailVerification',
    component: EmailVerification
  },
  {
    path: '/error',
    name: 'Error',
    component: Error,
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
