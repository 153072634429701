<template>
  <div class="container">        
    <div class="backdrop" v-if="showBackdrop"></div>
    <div class="ccontainer">
      <div class="headline-separator">
        <h3>Ihre Suchergebnisse</h3>
      </div>
      <transition appear>
      <div v-if="JWTToken && !runningSearch" class="d-flex align-items-stretch">
        <CruiseFilter :cruises="cruises" @emitCruisesFiltered="filterCruises()" />
        <CruisesList :cruises="cruises" />
      </div>
      <div v-else>
        <Spinner />
      </div>
      </transition>
    </div>
  </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity';
import { inject } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
//Components
import CruisesList from '../components/SearchResults/CruisesList.vue';
import Spinner from '../components/Spinner.vue'
import CruiseFilter from '../components/SearchResults/CruiseFilter.vue'
//Composables
import useBookingAPI from '../composables/useBookingAPI';

export default {
  components: { CruisesList, Spinner, CruiseFilter },
  props: ['searchCriteria', 'cruises'],
  setup(props, context) {
    const showBackdrop = ref(false);
    const blockContent = (status) => {
      showBackdrop.value = status
    }
    
    const JWTToken = ref(inject('JWTToken'));
    const router = useRouter();
    const route = useRoute()

    const cruises = ref(null);
    const runningSearch = ref(false);

    if(props.cruises) {
      //Cruises can also be passed as props to SerchResults.vue. But the normal way is to pass just the search params as URL parameters
      console.log('cruises als params',props.cruises)
      cruises.value = JSON.parse(props.cruises);
    } else if(!props.cruises && route.query) {
      //Search parameters are passed as GET-parameters (passed from Search.vue or by deeplink) and so the search can be performed
      const { BookingApiError, searchCruises } = useBookingAPI();

      runningSearch.value = true
      blockContent(true);
      searchCruises(route.query, JWTToken.value)
        .then((result) => {
            runningSearch.value = false;
            blockContent(false);
            
            if(!BookingApiError.value) {
                console.log('Search successfully submitted');
                console.log('Reisen:', result)
                cruises.value = result;
            }
        });
    } else {
      router.push({name: 'Search'});
    }

    const filterCruises = (filteredCruises) => {
      console.log('Filters applied!', cruises)
    }
    
    return { showBackdrop, cruises, JWTToken, runningSearch, filterCruises }
  }
}
</script>

<style>

</style>