//Modules
import { ref } from "vue";
import axios from "axios";
import VueJwtDecode from 'vue-jwt-decode';

const JWTerror = ref(null);
const JWTAuthAPIURL = process.env.VUE_APP_SEEREISEN_DE_AUTH_API + 'auth';

/**
 * Initializes the JWT token used to send requests to seereisen.de API.
 * It loads an existing token from the browser's local storage or fetches a fresh one from the Auth-API if no token exists and/or it is expired.
 * @returns {String} JWT token string or null if no token could be loaded/fetched
 */
 const initializeJWTToken = async() => {
  let JWTToken = null;
  let tokenAvailable = await saveJWTTokenToLocalStorage();
  if(tokenAvailable) {   
   JWTToken = getJWTToken();
  }
  return JWTToken;
}

/**
 * Fetches a new JWT Token from the booking API and saves it to the browser's local storage.
 * @returns {String} JWT token string
 */
const fetchJWTTokenFromAPI = async () => {
  JWTerror.value = null;

  console.log('Trying to fetch JWT Token from API...');
  try {
    const response = await axios.get(JWTAuthAPIURL);
    //console.log('Token API response', response);

    const responseData = response.data;
    const responseStatus = response.status;
    const responseMessage = response.statusText;
    const responseHeaders = response.headers;
    const responseError = response.data.error;
    const token = response.data.data.auth_token;
    
    if(responseStatus == 200 && responseError == null) {
      sessionStorage.setItem('JWTToken', token);
      JWTerror.value = null;
      return token;
    } else {
      JWTerror.value = 'Fehler beim Token-Abruf (API meldet: ' + responseError + ' [Status: '+responseStatus+'])';
      throw new Error('Fehler beim Token-Abruf (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }
  } catch(err) {
    console.log(err);
    JWTerror.value = 'Token konnte nicht abgerufen werden';
  }
  return token;
}

/**
 * Checks for an existing and valid (not expired) JWT Token in the browser's local storage. 
 * Fetches a new one from the Auth-API and saves it to the browser's local storage, if there is no token set yet or the existing token is close to the end of its validity (<6 min) or already exired.
 * @returns {Boolean} tokenAvailable true/false
 */
const saveJWTTokenToLocalStorage = async () => {
  let JWTtoken = '';
  let tokenAvailable = false;
  if(!sessionStorage.getItem('JWTToken') || sessionStorage.getItem('JWTToken') == '') {
    //No token exists -> go fetch one
    console.log('Need to fetch new token...');
    JWTtoken = await fetchJWTTokenFromAPI();
    if(!JWTerror.value) {
        tokenAvailable = true;
        console.log('Token successfully fetched :)');
    }
  } else {
      //Token already exists in session storage -> check for validity (currently only expiring date)
      console.log('Token existing -> Loading from session storage');
      JWTtoken = sessionStorage.getItem('JWTToken');
      JWTtoken = decodeJWTToken(JWTtoken);

      let curTime = new Date();
      let tokenExpDate = new Date(JWTtoken.exp*1000);
      if(tokenExpDate < curTime) {
        //Token is expired -> delete the current one get a new one
        console.log('Token expired (now: '+curTime.toLocaleString()+' > valid until: ' + tokenExpDate.toLocaleString() +').');
        sessionStorage.removeItem('JWTToken');
        tokenAvailable = await saveJWTTokenToLocalStorage();
      } else if(new Date((curTime.getTime() + 6 * 60000)) >= tokenExpDate ) {
        //Token is close to the end of its validity -> delete the current one and get a new one
        sessionStorage.removeItem('JWTToken');
        tokenAvailable = await saveJWTTokenToLocalStorage();
      } else {
        //Token is still valid -> everything is fine :)
        console.log('Token valid and not expired (now: '+curTime.toLocaleString()+' < valid until:' + tokenExpDate.toLocaleString() +')');
        tokenAvailable = true;
      }
  }
  return tokenAvailable;
}

/**
 * Loads the JWT token string from the browser's session storage
 * @returns {String} String of undecoded JWT token or false if no token exists in storage
 */
const getJWTToken = () => {
  return (sessionStorage.getItem('JWTToken')) ? sessionStorage.getItem('JWTToken') : false;
}

/**
 * JWT-Decodes a JWT token string
 * @param {String} token JWT token
 * @returns {Object} decoded JWT token
 */
const decodeJWTToken = (token) => {
  JWTerror.value = null;
  let decodedToken = VueJwtDecode.decode(token);
  return decodedToken;
}

const useJWTToken = () => {
  return  { JWTerror, fetchJWTTokenFromAPI, getJWTToken, decodeJWTToken, saveJWTTokenToLocalStorage, initializeJWTToken };
}

export default useJWTToken;