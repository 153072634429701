/**
 * Defines translations and conversion settings for different languages that the user can choose from
 */

const languages = [];

//German
languages['DE'] = {
  langName: 'DE',
  dateTimeLang: 'de-DE',

  monthNames: ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
  monthNamesShort: ['Jan','Feb','Mär','Apr','Mai','Jun','Jul','Aug','Sep','Okt','Nov','Dez'],

  cabinTypes: {0: 'k.A.', 1: 'Innenkabine', 2: 'Außenkabine', 3: 'Balkonkabine', 4: 'Suite'},
};

//English
languages['EN'] = {
  langName: 'EN',
  dateTimeLang: 'en-US',

  monthNames: ['January','February','March','April','May','June','July','August','September','October','November','December'],
  monthNamesShort: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
  
  cabinTypes: {0: 'n.A.', 1: 'Inside cabin', 2: 'Outside cabin', 3: 'Balcony cabin', 4: 'Suite'},
};

function getLanguage(langCode) {
  return languages[langCode];
}

const languageFunctions = () => {
  return  { getLanguage };
}

export default languageFunctions;
