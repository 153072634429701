<template>
  <div class="container">
    <div class="backdrop" v-if="showBackdrop"></div>
    <div class="ccontainer">    
      <div class="error" v-if="error!=''">{{ error }}</div>

      <div v-if="cruisesDataParsed">      
        <div class="cruise-details" :id="'route_'+uniqueTravelId">
          <div class="headline-separator">
            <h3>Reisedaten</h3>         
          </div>          
          <div class="d-flex justify-content-between">
            <div class="w-100">
              <h1>{{cruiseDetails.routeName}}</h1>
              <h2>{{cruiseDetails.shipName}} - {{cruiseDetails.shippingLineName}}</h2>
              <h4><i class="fa fa-fw fa-clock"></i> {{cruiseDetails.routeScheduleStartFormatted}} - {{cruiseDetails.routeScheduleEndFormatted}} ({{cruiseDetails.routeScheduleDuration}} Nächte)</h4>
              <div class="tags">
                <span class="badge bg-warning" v-for="region in cruiseDetails.destinations" :key="region.regionName">#{{region.regionName}}</span>&nbsp;
                <span class="badge bg-warning" v-for="region in cruiseDetails.destinations" :key="region.regionName">#{{cruiseDetails.shipName}}</span>&nbsp;
                <span class="badge bg-warning" v-for="region in cruiseDetails.destinations" :key="region.regionName">#{{cruiseDetails.shippingLineName.replace(' ','')}}</span>
              </div>
              <div class="mt-2">
                <span class="link link-primary" @click="toggleRoute"><i class="fas fa-route text-primary"></i> Routenverlauf anzeigen</span>
              </div>
              <transition appear @before-enter="beforeEnterRoute" @enter="enterRoute">
                <div v-if="showRoute">
                  <h4>Routenverlauf</h4>
                  <RouteDetails :uniqueTravelId="uniqueTravelId" />
                </div>             
              </transition>              
            </div>
            <div>
              <CruiseTitleimage :cruiseTitleimageSrc="cruiseTitleimageSrc" />
            </div>         
          </div>

          <div class="headline-separator">
            <h3>Termine &amp; Preise</h3>         
          </div>
          <div class="d-flex align-items-stretch">
            <div class="side-bar search-settings">
              <h5 class="sectionHead">Preisberechnung</h5>
              <div class="sectionContent">
                <div class="input-group input-group-sm p-2 mb-1">
                  <span class="input-group-text w-auto" id="num_adults">Erwachsene</span>
                  <input type="number" id="ss_adults" name="adults" class="form-control" aria-label="Erwachsene" aria-describedby="num_adults" v-model="calcCriteria.adults" min="1" max="5" :disabled="disableFilterForm" @change="updatePricesAuto()">
                </div>  
                <div class="input-group input-group-sm p-2 mb-1">
                  <span class="input-group-text w-auto" id="num_children">Kinder</span>
                  <input type="number" id="ss_children" name="children" class="form-control" aria-label="Kinder" aria-describedby="num_children" v-model="calcCriteria.children" min="0" max="3" :disabled="disableFilterForm" @change="updatePricesAuto()">
                </div>  
                <div class="input-group input-group-sm p-2 mb-1" v-if="calcCriteria.children>0">
                  <span class="input-group-text" id="age_child1">Alter 1. Kind</span>
                  <input type="number" id="ss_child1_age" name="child1_age" class="form-control" aria-label="Alter 1. Kind" aria-describedby="age_child1" v-model="calcCriteria.child1age" min="1" max="25" :disabled="disableFilterForm" @input="(calcCriteria.child1age>25)?calcCriteria.child1age=25:calcCriteria.child1age=calcCriteria.child1age" @change="updatePricesAuto()">
                </div>  
                <div class="input-group input-group-sm p-2 mb-1" v-if="calcCriteria.children>1">
                  <span class="input-group-text" id="age_child2">Alter 2. Kind</span>
                  <input type="number" id="ss_child2_age" name="child2_age" class="form-control" aria-label="Alter 2. Kind" aria-describedby="age_child2" v-model="calcCriteria.child2age" min="1" max="25" :disabled="disableFilterForm" @input="(calcCriteria.child2age>25)?calcCriteria.child2age=25:calcCriteria.child2age=calcCriteria.child2age" @change="updatePricesAuto()">
                </div>
                <div class="input-group input-group-sm p-2 mb-1" v-if="calcCriteria.children>2">
                  <span class="input-group-text" id="age_child3">Alter 3. Kind</span>
                  <input type="number" id="ss_child3_age" name="child3_age" class="form-control" aria-label="Alter 3. Kind" aria-describedby="age_child3" v-model="calcCriteria.child3age" min="1" max="25" :disabled="disableFilterForm" @input="(calcCriteria.child3age>25)?calcCriteria.child3age=25:calcCriteria.child3age=calcCriteria.child3age" @change="updatePricesAuto()">
                </div>
                <div class="input-group p-2 mb-1">
                  <button class="btn btn-primary w-100" :disabled="disableFilterForm" @click="updatePrices">Neu berechnen</button>
                </div>
              </div>

              <h5 class="sectionHead">Alternativtermine</h5>
              <div class="sectionContent">
                <div v-if="alternateDepartures.length>0" class="">
                  <div class="calendar-bar d-flex justify-content-around flex-wrap">
                    <div v-for="departure in alternateDepartures" :key="departure.uniqueTravelId" class="calendar-btn" :class="{selected: selectedDeparture==departure.uniqueTravelId}" @click="switchDeparture(departure.uniqueTravelId)">
                      <div class="month">{{departure.routeScheduleStartMonth}}</div>
                      <div class="day">{{departure.routeScheduleStartDate.getDate()}}</div>
                      <div class="year">{{departure.routeScheduleStartDate.getFullYear()}}</div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
            <div v-if="!noCabinsFound" class="w-100 mb-5">
              <div class="cabin-filters p-3">
                <CruiseCabinsFilter :cruiseOnlyPricesAvailable="cruiseOnlyPricesAvailable" :cruisePricesWithFlightAvailable="cruisePricesWithFlightAvailable" @emitFilterCabins="filterCabins" />
              </div>
              <div v-if="cruiseOnlyPricesAvailable" class="mt-3">
                <CruiseCabinsList :uniqueTravelId="uniqueTravelId" :pricesWithFlight="0" :cruisePricesForCabins="allCruisesOnlyPrices" :flightPrices="null" :cabinsListTitle="'Kabinenpreise ohne Flug'" :travelingPersonsText="travelingPersons" :numTabOpened="1" :filterId="'woflight'" />
              </div>
              <div v-if="cruisePricesWithFlightAvailable" class="mt-3">
                <CruiseCabinsList :uniqueTravelId="uniqueTravelId" :pricesWithFlight="1" :cruisePricesForCabins="allCruisesWithFlightPrices" :flightPrices="flightPrices" :flightClassSelected="flightClassSelected" :cabinsListTitle="'Kabinenpreise inklusive Flug'" :travelingPersonsText="travelingPersons" :numTabOpened="0" :filterId="'wflight'" @updateCabinsWithFlightPrices="updateCabinsWithFlightPrices" />
              </div>
            </div>
            <div v-else>
              <h3>Leider gibt es keine Kabinen auf dieser Reise, die Ihren Suchkriterien entsprechen :(</h3>
            </div>
          </div>

        </div>
      </div>
      <div v-else>
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script>
//Modules
import { ref } from '@vue/reactivity';
import { computed, onBeforeMount, onUpdated } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
import { provide, inject } from 'vue';
import gsap from 'gsap';
//Components
import Spinner from '../components/Spinner.vue';
import RouteDetails from '../components/CruiseDetails/RouteDetails.vue';
import CruiseTitleimage from '../components/CruiseDetails/CruiseTitleimage.vue';
import CruiseCabinsList from '../components/CruiseDetails/CruiseCabinsList.vue';
import CruiseCabinsFilter from '../components/CruiseDetails/CruiseCabinsFilter.vue';
//Composables
import useBookingAPI from '../composables/useBookingAPI';
import useMetaAPI from '../composables/useMetaAPI';
import helperFunctions from '../composables/helperFunctions';
import {parseCruiseDetailsPricesForDisplay} from '../composables/cruiseDetailsParser';

export default {
  props: ['uniqueTravelId', 'slug'],
  components: { Spinner, RouteDetails, CruiseTitleimage, CruiseCabinsList, CruiseCabinsFilter },
    setup(props)   {
    
    const lang = inject('lang');
    const JWTToken = ref(inject('JWTToken'));

    const showBackdrop = ref(false);
    const blockContent = (status) => {
      showBackdrop.value = status
    }      

    const error = ref('');

    const route = useRoute();
    const router = useRouter();
    
    const uniqueTravelId = ref(route.params.uniqueTravelId);
 
    const noCabinsFound = ref(false);
    const cruisesDataParsed = ref(false);
    
    const cruiseTitleimageSrc = ref('');
    const showRoute = ref(false);

    const cruiseDetails = ref('');
    const allCruisesOnlyPrices = ref([]);  //Array of Prices for inner, outer, balcony and suite cabin prices
    const cruiseOnlyPricesAvailable = ref(false);
    const allCruisesWithFlightPrices = ref([]); //Array of Prices for inner, outer, balcony and suite cabin prices
    const cruisePricesWithFlightAvailable = ref(false);
    const flightPrices = ref([]);

    const airportSelected = ref('0'); //will be set by child component CruiseCabinsList when user changes preferred airport in dropdown
    const flightClassSelected = ref('');
    const selectedFlightPrices = ref({});

    const travelingPersons = ref('');

    const alternateDepartures = ref([]);
    const selectedDeparture = ref('');

    const disableFilterForm = ref(false);

    /* For cabins filter */
    const showCabinTypes = ref([]);
    provide('showCabinTypes', showCabinTypes);
    const showPriceTypes = ref([]);
    provide('showPriceTypes', showPriceTypes);    

    const { getCalcCriteria, setCalcCriteria, convertYMDHISToDate } = helperFunctions();
    const calcCriteria = ref(getCalcCriteria());

    const { BookingApiError, getCruiseDetails } = useBookingAPI();
    const { MetaApiError, getRegions } = useMetaAPI();

    onBeforeMount(() => {
      error.value = '';    
      fetchCruiseDetails();
    });

    onUpdated(() => {
      selectedDeparture.value = uniqueTravelId.value;   
    });

    /**
     * Stores changed (by form on the left sidebar) calcCriteria to the local storage and performs a new cabin/prices fetching from the API for the current cruise/departure
     */
    const updatePrices = () => {
      // console.log('Neu-Berechnung der Preise...');
      setCalcCriteria(calcCriteria.value);
      fetchCruiseDetails();
    }

    //EXPERIMENTAL: Automatic update function, triggered when formfield values are changed
    const updatePricesAuto = () => {
      disableFilterForm.value = true;
      setCalcCriteria(calcCriteria.value);
      fetchCruiseDetails();
      setTimeout(() => disableFilterForm.value = false, 2000);
    }

    /**
     * Fetches prices and details from the Booking-API for the given departure by its uniqueTravelId and the current calcCriteria (num adults, children, age of children)
     */
    function fetchCruiseDetails() {
      blockContent(true);

      //For display above the list of cabins/prices
      travelingPersons.value = ''.concat(calcCriteria.value.adults, ' Erwachsene', (calcCriteria.value.adults == 1) ? 'n' : '');
      if(calcCriteria.value.children > 0) travelingPersons.value += ', '.concat(calcCriteria.value.children, ' Kind', (calcCriteria.value.children > 1) ? 'er' : '');

      if(JWTToken.value !== null && typeof JWTToken.value !== 'undefined') {
        getCruiseDetails(uniqueTravelId.value, JWTToken.value, calcCriteria.value)
          .then((result) => {
            if(!result) {
              noCabinsFound.value = true;
              cruisesDataParsed.value = true; //Trigger for child components to be displayed after successful parsing
              blockContent(false);
            } else {
              //Fetched Results from Booking-API
              cruiseDetails.value = result.cruiseDetails; 
              if(result.alternativeDepartures) alternateDepartures.value = result.alternativeDepartures;

              //General cruise details
              cruiseDetails.value.destinations = [];
              getRegions("", JWTToken.value)
                .then((regionsResult) => {
                  Object.keys(regionsResult.regions).forEach((key) => {
                    if(cruiseDetails.value.routeRegions.includes(regionsResult.regions[key].regionId.toString())) cruiseDetails.value.destinations.push(regionsResult.regions[key]) //Pushes all route destinations with RouteId and RouteName to array
                  })
                })
                .catch((err) => { console.log(err), error.value += MetaApiError });

              //Formatted Cruise Start and End Dates
              let dateFormatOptions = {weekday:'short', year: 'numeric', month: 'numeric', day: 'numeric'};
              cruiseDetails.value.routeScheduleStartFormatted = convertYMDHISToDate(cruiseDetails.value.routeScheduleStart).toLocaleDateString(lang.dateTimeLang, dateFormatOptions);
              cruiseDetails.value.routeScheduleEndFormatted = convertYMDHISToDate(cruiseDetails.value.routeScheduleEnd).toLocaleDateString(lang.dateTimeLang, dateFormatOptions);

              //Fetch Dates and cruiseId of all Depatures
              if(alternateDepartures.value.length>0) {
                alternateDepartures.value.forEach((key, index) => {
                  alternateDepartures.value[index].routeScheduleStartDate = convertYMDHISToDate(alternateDepartures.value[index].routeScheduleStart); //Deutsches Datumsformat ergänzen
                  alternateDepartures.value[index].routeScheduleStartMonth = lang.monthNamesShort[alternateDepartures.value[index].routeScheduleStartDate.getMonth()];

                  //TEMPORÄRE LÖSUNG
                  if(alternateDepartures.value[index].uniqueTravelId == uniqueTravelId.value) {
                    cruiseDetails.value.cruiseId = alternateDepartures.value[index].cruiseId;  //TEMPORÄRE LÖSUNG!! Die cruiseId wird NOCH für die Vacancy-Prüfung benötigt
                  }
                  //TEMPORÄRE LÖSUNG ENDE  
                });
              } else {
                console.log('Konnte keine Departures für diese Route ermitteln!');
              }

              //Cruise Title Image
              cruiseTitleimageSrc.value = 'https://media.xmlteam.de/files/aida-cruises/aidabella/schiff/4.jpeg';
              
              //Cruise only Prices
              if(result.cruiseOnlyPrices) {
                allCruisesOnlyPrices.value = parseCruiseDetailsPricesForDisplay(result.cruiseOnlyPrices, lang, 'woflight');
                if(allCruisesOnlyPrices.value.length>0) {
                  cruiseOnlyPricesAvailable.value = true; //Trigger for child component CruiseCabinsList
                  //Check which cabin filters to show & activate
                  showPriceTypes.value.push('woflight');
                  allCruisesOnlyPrices.value.forEach(el => { if(!showCabinTypes.value.includes(el.cabinType)) showCabinTypes.value.push(el.cabinType) });
                } 
              }
              
              //Cruises with Flight prices
              if(result.cruiseWithFlight) {

              flightPrices.value = result.cruiseWithFlight.flightPrices;  //Flights only prices
              selectedFlightPrices.value = getSelectedFlightPrices(); //Flight prices for the currently in dropdown selected departure airport
              console.log('selectedFlightPrices (Nur Preise des gewählten Airports)', selectedFlightPrices.value);                

                //Delete all cruises with flight that don't match the user-selected departure airport
                result.cruiseWithFlight.prices = result.cruiseWithFlight.prices.filter(item => {
                   return (
                     item.flightStartAirport == selectedFlightPrices.value.ccStartAirport //Abflug-Flughafen...
                     &&
                     item.pmc == selectedFlightPrices.value.ccCode  //...und Preiscode müssen übereinstimmen mit der User-Wahl im Dropdown
                     ); 
                });

                //Prices for flights are defined by selected departure airport and pmc. So they need to be set for each cruise with flight
                result.cruiseWithFlight.prices.forEach(function(part, index) {
                  result.cruiseWithFlight.prices[index].priceFlightSingle = selectedFlightPrices.value.ccPriceAdult;
                  result.cruiseWithFlight.prices[index].priceFlight34 = selectedFlightPrices.value.ccPriceAdult;
                  result.cruiseWithFlight.prices[index].priceFlightChild = selectedFlightPrices.value.ccPriceChild;
                  result.cruiseWithFlight.prices[index].priceFlightChild34 = selectedFlightPrices.value.ccPriceChild;  
                });

                allCruisesWithFlightPrices.value = parseCruiseDetailsPricesForDisplay(result.cruiseWithFlight, lang, 'wflight');          

                if(allCruisesWithFlightPrices.value.length>0) {
                  cruisePricesWithFlightAvailable.value = true; //Trigger for child component CruiseCabinsList
                  //Check which cabin filters to show & activate
                  showPriceTypes.value.push('wflight');
                  allCruisesWithFlightPrices.value.forEach(el => { if(!showCabinTypes.value.includes(el.cabinType)) showCabinTypes.value.push(el.cabinType) });
                }
                
              }

              cruisesDataParsed.value = true; //Trigger for child components to be displayed after successful parsing
              blockContent(false);

              console.log('CruiseDetails (Details zur Reise)', cruiseDetails.value);
              console.log('allCruisesOnlyPrices (Alle Kabinenpreise ohne Flug)', allCruisesOnlyPrices);
              console.log('allCruisesWithFlightPrices (Alle Kabinenpreise mit Flug ab gewähltem Airport)', allCruisesWithFlightPrices);
              console.log('flightPrices (Alle Flugpreise für alle Airports)', flightPrices.value);
            }            
          })
          .catch((err) => { console.log(err), error.value += BookingApiError });
      } else {
        router.push({name:'Home'});
      }
    }

    //The flight prices for the selected departure airport that will be passed to the child component(s) (e.g. CruiseCabinsList.vue)
    const getSelectedFlightPrices = () => {
      let fp = {};
      if(airportSelected.value != '0') {
        //airport selected (e.g. DUSPREMIUM)
        if(flightClassSelected.value!='') {
          //flight Class ccClass selected (Y, B, P)
          Object.keys(flightPrices.value).forEach((key) => {
            if(flightPrices.value[key].ccStartAirport == airportSelected.value.substring(0,3) && flightPrices.value[key].ccCode == airportSelected.value.substring(3) && flightPrices.value[key].ccClass == flightClassSelected.value) fp = flightPrices.value[key];
          });          
        } else {
          Object.keys(flightPrices.value).forEach((key) => {
            if(flightPrices.value[key].ccStartAirport == airportSelected.value.substring(0,3) && flightPrices.value[key].ccCode == airportSelected.value.substring(3)) fp = flightPrices.value[key];
            flightClassSelected.value = fp.ccClass;
          });
        }
      } else {
        //cheapest
        if(flightPrices.value) {
          Object.keys(flightPrices.value).forEach((key) => {
            if(typeof fp.ccPriceAdult === 'undefined' || flightPrices.value[key].ccPriceAdult < fp.ccPriceAdult) fp = flightPrices.value[key];
            flightClassSelected.value = fp.ccClass;
          });
        }
      }
      return fp;
    };

    const updateCabinsWithFlightPrices = (airportAndPmc, flightClass) => {
      airportSelected.value = airportAndPmc;
      flightClassSelected.value = flightClass;
      // selectedFlightPrices.value = getSelectedFlightPrices();
      fetchCruiseDetails();
    }    

    /**
     * Navigating to another departure of this cruise by clicking/changing the departure date in the dropdown
     */
    const switchDeparture = (newUniqueTravelId) => {
      console.log('Navigating to CruiseDetails:', {uniqueTravelId: newUniqueTravelId, slug: props.slug });
      router.push({ name: 'CruiseDetails', params: {uniqueTravelId: newUniqueTravelId, slug: props.slug }});
      uniqueTravelId.value = newUniqueTravelId;
      fetchCruiseDetails();
    };


    /**
     * Updates the cabinTypes to be displayed in the CruiseCabinsList
     */
    const filterCabins = (cabinType, priceType) => {
      if(cabinType !== null && cabinType > 0) {
        if(showCabinTypes.value.includes(cabinType)) showCabinTypes.value = showCabinTypes.value.filter(ct => ct != cabinType);
        else showCabinTypes.value.push(cabinType);
      }
      if(priceType !== null && typeof priceType === 'string') {
        if(showPriceTypes.value.includes(priceType)) showPriceTypes.value = showPriceTypes.value.filter(ct => ct != priceType);
        else showPriceTypes.value.push(priceType);          
      }
      console.log(showPriceTypes);
    };

    /**
     * Toggles hidden form fields
     */
    const toggleRoute = () => {
      showRoute.value = !showRoute.value;
    } 
    
    const beforeEnterRoute = (el) => {
      el.style.opacity = 0;
      el.style.transform = 'translateX(100px)';
      el.style.backgroundColor = '#2777bd';
    }
    const enterRoute = (el, done) => {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        backgroundColor: 'transparent',
        duration: 0.25,
        onComplete: done,
        delay: el.dataset.index * 0.2 //gets the index of the element (the cruise) from the :data-index="index"
      })
    }    

    return { error, showBackdrop, disableFilterForm, updatePrices, updatePricesAuto, flightClassSelected, selectedFlightPrices,
            uniqueTravelId, calcCriteria, travelingPersons, noCabinsFound,
            showRoute, toggleRoute, beforeEnterRoute, enterRoute,
            updateCabinsWithFlightPrices,
            alternateDepartures, selectedDeparture, switchDeparture, 
            cruiseDetails, cruiseTitleimageSrc, cruisesDataParsed,
            cruiseOnlyPricesAvailable, allCruisesOnlyPrices,
            cruisePricesWithFlightAvailable, allCruisesWithFlightPrices, flightPrices,
            filterCabins
            }
  }
}




</script>

<style scoped>
.cabin-filters {
  background: rgba(var(--bs-primary-rgb),.15);
  border: 1px solid rgba(var(--bs-primary-rgb),.5);
  border-radius: 0 0 5px 5px;
  border-top: none;
}
</style>