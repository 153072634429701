import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import useJWTToken from './composables/useJWTAuth';

import './assets/css/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import { projectAuth } from './config/firebase';

let app;
var JWTToken; 

projectAuth.onAuthStateChanged(async () => {  //App will be created when Firebase User-Status is received
  if(!app) {
    fetchJWTToken()
    .then(() => {
      app = createApp(App, {});
      app
        .use(router)
        .provide('JWTToken', JWTToken)
        .mount('#app');
        
      constantlyFetchNewToken();
    });
  }
});

function constantlyFetchNewToken() {
  setTimeout(() => {
    fetchJWTToken();
    constantlyFetchNewToken();
  }, 300000); //Every 5 minutes
}

async function fetchJWTToken() {
  const { initializeJWTToken } = useJWTToken();

  await initializeJWTToken()
  .then((result) => { 
    let tokenIsValid = false;
    JWTToken = result; 

    if(JWTToken !== null) tokenIsValid = true;

    if(!tokenIsValid) { 
      throw new Error('Token invalid!');
    } else {
      if(app) app.provide('JWTToken', JWTToken);
    } 
  })
  .catch((err) => {
    // console.log(err);
  });
}