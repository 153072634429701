//Modules
import { ref } from "vue";
import axios from "axios";
import helperFunctions from "./helperFunctions";
import { sortCruiseList, getSortingSettings } from "./cruiseListSorter";

const BookingApiError = ref(null);
const bookingAPIUrl = process.env.VUE_APP_SEEREISEN_DE_BOOKING_API;

const { getSearchCriteria } = helperFunctions()

/**
 * Fetches all shipping companies from Booking-API
 * @param {String} token JWT token
 * @returns {Object} Object of all shipping companies
 */
const getAllCompanies = async (token) => {
  let endpoint = 'shippinglines';
  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+token
    }
  }  
  try{
    const response = await axios.get(bookingAPIUrl.concat(endpoint), apiRequestConfig);
    //console.log(response)

    const responseData = response.data;
    const responseStatus = response.status;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == null) {
      let shippingCompanies = {
        shippinglinesCount: responseData.data.shippinglinesCount,
        shippinglines: responseData.data.shippinglines
      }
      return shippingCompanies;
    } else {
      throw new Error('Fehler beim Abruf der Companies (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }
  } catch(err) {
    BookingApiError.value = 'Companies konnten nicht abgerufen werden (Error: '+err.message+')';
    console.log(BookingApiError.value);
  }
  return false;
}

/**
 * Fetches cruises from Booking-API depending on passed search criteria object
 * @param {Object} searchCriteria with search criteria settings
 * @param {String} token JWT Token
 * @returns {Object} Object of cruises matching the given search criteria and sorted by current sorting settings
 */
const searchCruises = async (searchCriteria, token) => {
  BookingApiError.value = null;

  let endpoint = 'cruises';
  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+token
    }
  }
  
  let searchParams = new URLSearchParams(searchCriteria).toString();
  try {
    const response = await axios.get(bookingAPIUrl.concat(endpoint, '?', searchParams), apiRequestConfig);
    console.log('API Cruises request:', bookingAPIUrl.concat(endpoint, '?', searchParams));
    //console.log(response);

    const responseData = response.data;
    const responseStatus = response.status;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == null) {
      const cruises = { 
        totalCruisesCount: responseData.data.totalCruisesCount,
        cruises: responseData.data.cruises
      }

      let sortingSettings = getSortingSettings();
      const sortedCruises = sortCruiseList(cruises, sortingSettings.sortBy, sortingSettings.sortDir);
      return sortedCruises;
    } else {
      throw new Error('Fehler beim Abruf der Reisen (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }    
  } catch(err) {
    BookingApiError.value = 'Verfügbare Reisen konnten nicht abgerufen werden (Error: '+err.message+')';
    console.log(BookingApiError.value);
  }
  return true;
}

/**
 * Fetches a cruise's details from the Booking-API
 * @param {String} uniqueTravelId uniqueTravelId (e.g. 'DV-220224-2-15-44-14')
 * @param {String} token JWT token
 * @returns {Object} Cruise details containing route regions, timings, cabins and prices
 * 
 * @todo Currently fetching by passed cruiseId -> Needs eventually to be changed to TCC's uniqueTravelId
 */
const getCruiseDetails = async(uniqueTravelId, token, searchCriteria=getSearchCriteria()) => {
  BookingApiError.value = null;

  let endpoint = 'cruises/'.concat(uniqueTravelId);
  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+token
    }
  }

  const numPersons = {};
  //const searchCriteria = getSearchCriteria();
  if(searchCriteria) {
    numPersons.adults = searchCriteria.adults;
    if(searchCriteria.children>0) numPersons.k1 = searchCriteria.child1age;
    if(searchCriteria.children>1) numPersons.k2 = searchCriteria.child2age;
    if(searchCriteria.children>2) numPersons.k3 = searchCriteria.child3age;
  } else {
    numPersons.adults = 2;
  }
  let searchParams = new URLSearchParams(numPersons).toString();
  
  try {
    const response = await axios.get(bookingAPIUrl.concat(endpoint, '?', searchParams), apiRequestConfig);
    console.log('API Cruises Details request:', bookingAPIUrl.concat(endpoint, '?', searchParams));
    //console.log(response);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseError = response.data.error;

    if(responseStatus == 200 && responseError == null) {
      return responseData;
    } else {
      throw new Error('Fehler beim Abruf der Reise-Details (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }
  } catch(err) {
    BookingApiError.value = err.message;
    console.log(BookingApiError.value);
  }
  return false;
}

/**
 * Checking the vacancy of a cruise's cabin/pricemodel, depending on the num of adults and (optional) children (defined by their age).
 * @param {String} uniqueTravelId uniqueTravelId
 * @param {String} pmc PriceModelCode (e.g. 'PREMIUM')
 * @param {String} class Flight's seating class (e.g. 'Y' for economy, 'B' for Business or 'P' for ?)
 * @param {String} cabin Cabin (e.g. 'IA')
 * @param {String} cabinType CabinType (e.g. '1')
 * @param {Integer} [adults=2] Number of adults
 * @param {Integer} [k1=0] Age of Child 1
 * @param {Integer} [k2=0] Age of Child 2
 * @param {Integer} [k3=0] Age of Child 2
 * @param {String} token JWT Token
 * @returns {Object} Vacancy result object containing information on cabin assignment, rq, oid, baid and total price
 * 
 */
const getVacancy = async(uniqueTravelId, pmc, cabin, cabinType, pricesWithFlight=0, airportSelected='', flightClass='', adults=2, k1=0, k2=0, k3=0, token) => {
  BookingApiError.value = null;

  let endpoint = 'vacancy/'.concat(uniqueTravelId,'/',pmc,'/',cabin,'/',cabinType);
  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+token
    }
  }

  const params = {};
  params.adults = adults;
  if(k1>0) params.k1 = k1;
  if(k2>0) params.k2 = k2;
  if(k3>0) params.k3 = k3;
  params.is_flight = (pricesWithFlight=='1') ? 1 : 0;
  if(pricesWithFlight=='1') {
    params.start_airport = airportSelected;
    params.class = flightClass;
  }

  let searchParams = new URLSearchParams(params).toString();  

  try {
    const response = await axios.get(bookingAPIUrl.concat(endpoint, '?', searchParams), apiRequestConfig);
    console.log('API Vacancy request:', bookingAPIUrl.concat(endpoint, '?', searchParams));
    console.log(response);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseError = response.data.error;
    const responseCode = response.data.code;

    if(responseStatus == 200) {
      if(responseCode != 200) {
        throw new Error('Kabine ist nicht verfügbar (API meldet: ' + responseError + ' [Responsecode: '+responseCode+'])')
      } else {
        return responseData;
      }
    } else {
      throw new Error('Fehler beim Abruf der Vacancy (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }
  } catch(err) {
    BookingApiError.value = err.message;
    console.log(BookingApiError.value);
  }
  return false;  
};

/**
 * Gets all free cabins for the provided oid
 * @param {String} oid oid that you got from the getVacancy() API-request
 * @param {String} token JWT Token
 * @returns 
 */
const getCabins = async(oid, token) => {
  BookingApiError.value = null;

  let endpoint = 'cabins';
  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+token
    }
  }

  const params = {};
  params.oid = oid;

  let searchParams = new URLSearchParams(params).toString();  

  try {
    const response = await axios.get(bookingAPIUrl.concat(endpoint, '?', searchParams), apiRequestConfig);
    console.log('API Cabins request:', bookingAPIUrl.concat(endpoint, '?', searchParams));
    console.log(response);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseError = response.data.error;
    const responseCode = response.data.code;

    if(responseStatus == 200) {
      if(responseCode != 200) {
        throw new Error('Kabinenabruf ist nicht möglich (API meldet: ' + responseError + ' [Responsecode: '+responseCode+'])')
      } else {
        return responseData;
      }
    } else {
      throw new Error('Fehler beim Abruf der Kabinen (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }
  } catch(err) {
    BookingApiError.value = err.message;
    console.log(BookingApiError.value);
  }
  return false;  
};

/**
 * Sends a booking request (action='BR') or booking (action='B') to the booking API
 * @param {String} uniqueTravelId 
 * @param {String} baid 
 * @param {String} action 'BR' or 'B'
 * @param {String} cabinno cabin number or -1 if no cabin needs to be selected
 * @param {Array} registrant registrant's personal data
 * @param {Array} travelers all traveling persons array
 * @param {String} token JWT-Token
 * @param {Object} user User-Object for logged in user
 */
const sendBookingRequest = async(uniqueTravelId, baid, action='BR', cabinno='-1', registrant, travelers, token, user=null) => {
  BookingApiError.value = null;

  let endpoint = 'bookings/'.concat(uniqueTravelId);
  let apiRequestConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer '+token
    }
  }

  let bookingParams = {};
  bookingParams.baid = baid;
  bookingParams.action = action;
  if(cabinno != '-1') bookingParams.cabinno = cabinno; 

  if(user !== null && typeof user !== 'undefined') {
    if(user.accessToken !== null && typeof user.accessToken !== 'undefined') bookingParams.firebase_token = user.accessToken;
  }

  registrant.phone = registrant.phone_prefix+''+registrant.phone;
  registrant.mobile = registrant.mobile_prefix+''+registrant.mobile;

  bookingParams.registrant = registrant; 

  let x = 1;
  travelers.forEach((traveler) => {
    //traveler1[title, firstname, lastname, birthday]
    bookingParams['traveler'+x] = traveler; 
    x++;
  });
  
  // let searchParams = new URLSearchParams(params).toString();  
  //searchParams.replace('%5B','[').replace('%5D',']');

  try {
    const response = await axios.post(bookingAPIUrl.concat(endpoint), bookingParams, apiRequestConfig);
    console.log('API Booking request:', bookingAPIUrl.concat(endpoint), bookingParams);
    console.log(response);

    const responseData = response.data.data;
    const responseStatus = response.status;
    const responseError = response.data.error;
    const responseCode = response.data.code;

    if(responseStatus == 200) {
      if(responseCode != 200) {
        throw new Error('Eine Buchungsanfrage/Buchung ist nicht möglich (API meldet: ' + responseError + ' [Responsecode: '+responseCode+'])')
      } else {
        return responseData;
      }
    } else {
      throw new Error('Fehler beim Senden der Buchungsanfrage/Buchung (API meldet: ' + responseError + ' [Status: '+responseStatus+'])');
    }
  } catch(err) {
    BookingApiError.value = err.message;
    console.log(BookingApiError.value);
  }
  return false;  
};

const useBookingAPI = () => {
  return  { BookingApiError, getAllCompanies, searchCruises, getCruiseDetails, getVacancy, getCabins, sendBookingRequest };
}

export default useBookingAPI;