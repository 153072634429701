<template>
  <div>
    <p>
      Erstellen Sie Ihren persönlichen Login für das Seereisen.de-Dashboard und verwalten Sie Ihre Reisebuchungen, legen Sie individuelle Benachrichtigungen
      an und werden Sie sofort benachrichtigt, wenn Ihre Wunschkabine oder Reise zu einem bestimmten Preis buchbar ist.
    </p>
    <form @submit.prevent="handleSubmit">
      <div class="input-group mb-3">
        <span class="input-group-text w-50">Ihr gewünschter Benutzername</span>
        <input class="form-control" type="text" required placeholder="Display Name" v-model="displayName">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text w-50">Ihre E-Mail Adresse</span>
        <input class="form-control" type="email" required placeholder="E-Mail" v-model="email"> 
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text w-50">Ihr Passwort</span>
        <input class="form-control" type="password" required placeholder="Password" v-model="password">
      </div>
      <button class="btn btn-primary">Jetzt Registrieren</button>
      <div class="error" v-if="logonError!=null">{{ logonError }}</div>
    </form>    
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import useFirebaseLogon from '../../composables/useFirebaseLogon'

export default {
  setup(props, context) {
    const { logonError, signup } = useFirebaseLogon()

    //refs
    const displayName = ref('')
    const email = ref('')
    const password = ref('')

    const handleSubmit = async () => {
      await signup(email.value, password.value, displayName.value);
      if(!logonError.value) {
        console.log('User signed up')
        context.emit('signup')
      }
    }

    return { displayName, email, password, handleSubmit, logonError }
  }
}
</script>

<style>

</style>